import React from 'react'
import DigitalMarketingServicesHero from '../media/digital-marketing-services.jpg'
import seoIcon from '../media/Seo-icon.avif'
import triangle from '../media/triangle.png'
import globeIcon from '../media/globe-icon.png'
import globalLocalIcon from '../media/globe-local-icon.png'
import ecommerceIcon from '../media/ecommerce-icon.png'
import enterpriseIcon from '../media/enterprise-icon.png'
import ppcAdsIcon from '../media/ppc-ads-icon-1.png'
import linkedinSeoIcon from '../media/linkedin-seo-icon.png'
import socialMediaIcon from '../media/social-media-icon.png'
import googleAdsIcon from '../media/google-ads-icon.png'
import brandValuesIcon from '../media/brand-values-icon.png'
import socialMediaMarketingIcon from '../media/social-media-marketing-icon.png'
import userTrafficIcon from '../media/user-traffic-icon.png'
import badgeIcon from '../media/badge-icon.png'
import financialCompetetor from '../media/competative-growth-icon.png'
import conversionIcon from '../media/conversion-icon.png'
import FaqSectionComponent from './CommonComponents/FaqSectionComponent/FaqSectionComponent'
function DigitalMarketingPageContent() {

  const faqHeading = '';

    const faqData = [
      // Define your FAQ data here with questions and answers
      {
        q: "What is Digital marketing?",
        a: "It’s an initiative and specific service that is used to enhance the visibility and appearance of the products.",
      },
      {
        q: "What are the benefits of digital marketing?",
        a: "You can enhance your visibility and get a broad area of customers and once your business flourishes it will get higher revenue generation.  ",
      },
      {
        q: "What types of digital marketing services are available?",
        a: "It has multiple features and benefits like quick results efficient marketing results, monitoring, and all. ",
      },
      {
        q: "What is content marketing?",
        a: "It becomes effective just after the campaign setup, however considering the quality of leads it can be said perfectly.",
      },
      {
        q: "What is the role of analytics in digital marketing?",
        a: "It will vary on the range of campaigns and all for what it is used.",
      },
    ];
  return (
    <div>
      <div className="pt-20">
        <div className="my-4 section1 px-5 flex flex-col lg:flex-row gap-12">
          <div className="lg:w-[50%] pl-4">
            <h1 className="text-3xl mt-6 font-montserrat font-semibold">
              Digital Marketing Services
            </h1>
            <p className="text-justify mt-1">
              Unlock the potential of your business with our comprehensive
              digital marketing services. We specialize in SEO to boost your
              search engine rankings and increase organic traffic. Our PPC
              campaigns are designed to maximize ROI with targeted ads. Engage
              your audience with compelling social media marketing strategies
              across all major platforms. Enhance your online presence through
              expert content marketing and blog management. Utilize email
              marketing to nurture leads and drive conversions. Our web design
              and development services ensure your site is both attractive and
              functional. Gain valuable insights with our detailed analytics and
              reporting. Trust us to manage your online reputation and brand
              identity. Partner with us for customized strategies that deliver
              measurable results.
            </p>
            <a href="/contact#contact">
              <button className="border mt-5 px-2 py-1 text-[1rem] font-semibold rounded-md bg-[#2186f9] text-white">
                Request a Demo
              </button>
            </a>
          </div>
          <div className="lg:w-[50%]">
            <img
              className=" rounded-lg mt-6"
              src={DigitalMarketingServicesHero}
              alt="Digital Marketing"
            />
          </div>
        </div>
        <div className="mt-16 section1  ">
          <h2 className="text-center text-3xl font-semibold ">
            we provide the Digital marketing services{" "}
          </h2>
          <div className="mt-8 px-12 lg:px-5 flex flex-col lg:flex-row flex-wrap justify-center items-center gap-8 lg:gap-12 ">
            <div className="relative w-[100%] lg:w-[18%] h-[13rem] flex flex-col curser-pointer duration-300 ease-in  shadow-blue-300 bg-[#C2AFF040] border border-gray-200 justify-center items-center rounded hover:shadow-lg">
              <div className=" flex flex-col items-center justify-center">
                <img src={globeIcon} alt="globe-img" className="border w-12" />
                <p className="font-bold text-center">Web SEO</p>
              </div>
              <p className="px-2 text-[.90rem] text-start">
                <span className="font-semibold">Web SEO :</span> Enhance your
                website's visibility and ranking on search engines to drive
                organic traffic.
              </p>
            </div>
            <div className="relative w-[100%] lg:w-[18%] h-[13rem] flex flex-col curser-pointer duration-300 ease-in  shadow-blue-300 bg-[#C2AFF040] border border-gray-200 justify-center items-center rounded hover:shadow-lg">
              <div className=" flex flex-col items-center justify-center">
                <img
                  src={globalLocalIcon}
                  alt="globe-img"
                  className="border w-12"
                />
                <p className="font-bold text-center">Local SEO</p>
              </div>
              <p className="px-2 text-[.90rem] text-start">
                <span className="font-semibold">Local SEO :</span> Improve your
                business's visibility in local searches to attract nearby
                customers.
              </p>
            </div>
            <div className="relative w-[100%] lg:w-[18%] h-[13rem] flex flex-col curser-pointer duration-300 ease-in  shadow-blue-300 bg-[#C2AFF040] border border-gray-200 justify-center items-center rounded hover:shadow-lg">
              <div className=" flex flex-col items-center justify-center">
                <img
                  src={ecommerceIcon}
                  alt="globe-img"
                  className="border w-12"
                />
                <p className="font-bold text-center">E-Commerce SEO</p>
              </div>
              <p className="px-2 text-[.90rem] text-start">
                <span className="font-semibold">E-Commerce SEO :</span> Optimize
                your online store to drive targeted traffic and increase sales
                conversions.
              </p>
            </div>
            <div className="relative w-[100%] lg:w-[18%] h-[13rem] flex flex-col curser-pointer duration-300 ease-in  shadow-blue-300 bg-[#C2AFF040] border border-gray-200 justify-center items-center rounded hover:shadow-lg">
              <div className=" flex flex-col items-center justify-center">
                <img
                  src={enterpriseIcon}
                  alt="globe-img"
                  className="border w-12"
                />
                <p className="font-bold text-center">Enterprise SEO</p>
              </div>
              <p className="px-2 text-[.90rem] text-start">
                <span className="font-semibold">Enterprise SEO :</span>{" "}
                Implement advanced SEO strategies for large-scale websites to
                maximize impact.
              </p>
            </div>
            <div className="relative w-[100%] lg:w-[18%] h-[13rem] flex flex-col curser-pointer duration-300 ease-in  shadow-blue-300 bg-[#C2AFF040] border border-gray-200 justify-center items-center rounded hover:shadow-lg">
              <div className=" flex flex-col items-center justify-center">
                <img src={ppcAdsIcon} alt="globe-img" className="border w-12" />
                <p className="font-bold text-center">PPC Ads</p>
              </div>
              <p className="px-2 text-[.90rem] text-start">
                <span className="font-semibold">PPC Ads :</span> Generate
                immediate traffic and leads with optimized pay-per-click
                advertising.
              </p>
            </div>
            <div className="relative w-[100%] lg:w-[18%] h-[13rem] flex flex-col curser-pointer duration-300 ease-in  shadow-blue-300 bg-[#C2AFF040] border border-gray-200 justify-center items-center rounded hover:shadow-lg">
              <div className=" flex flex-col items-center justify-center">
                <img
                  src={linkedinSeoIcon}
                  alt="globe-img"
                  className="border w-12"
                />
                <p className="font-bold text-center">LinkedIn Marketing</p>
              </div>
              <p className="px-2 text-[.90rem] text-start">
                <span className="font-semibold">LinkedIn Marketing :</span>{" "}
                Connect with industry professionals and generate quality B2B
                leads on LinkedIn.
              </p>
            </div>
            <div className="relative w-[100%] lg:w-[18%] h-[13rem] flex flex-col curser-pointer duration-300 ease-in  shadow-blue-300 bg-[#C2AFF040] border border-gray-200 justify-center items-center rounded hover:shadow-lg">
              <div className=" flex flex-col items-center justify-center">
                <img
                  src={socialMediaIcon}
                  alt="globe-img"
                  className="border w-12"
                />
                <p className="font-bold text-center">Social Media Marketing</p>
              </div>
              <p className="px-2 text-[.90rem] text-start">
                <span className="font-semibold">Social Media Marketing :</span>{" "}
                Grow your audience and increase brand awareness on major social
                platforms.
              </p>
            </div>
            <div className="relative w-[100%] lg:w-[18%] h-[13rem] flex flex-col curser-pointer duration-300 ease-in  shadow-blue-300 bg-[#C2AFF040] border border-gray-200 justify-center items-center rounded hover:shadow-lg">
              <div className=" flex flex-col items-center justify-center">
                <img
                  src={googleAdsIcon}
                  alt="globe-img"
                  className="border w-12"
                />
                <p className="font-bold text-center">Google Ads</p>
              </div>
              <p className="px-2 text-[.90rem] text-start">
                <span className="font-semibold">Google Ads :</span> Run
                precision-targeted ad campaigns on Google to drive traffic and
                conversions.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-12 pb-6 section1 px-5 bg-[#FFF8E8]">
          <h2 className=" pt-6 text-2xl lg:text-3xl text-center flex-1">
            Our Specialized <b> Digital Marketing Services in Delhi</b>
          </h2>
          <p className="mt-1 px-3 lg:px-[8rem] text-[.95rem] text-center">
            We can be your reliable 'DIGITAL' marketing services partner. Being
            a pioneer of the market, we have been closely working with our
            clients since our inception.
          </p>
          <div className="mt-4 lg:px-[9rem] py-4 flex flex-row flex-wrap gap-4 justify-center items-center">
            <div className="group/head min-h-[280px] w-[19rem] lg:w-[17rem] border border-[#FCD581] rounded-xl  hover:shadow-md hover:bg-[#e4fbd9] duration-300 ease-in text-center ">
              <img
                src={seoIcon}
                alt="seoIcon"
                className=" rounded-[50%] w-10 h-10 my-4 m-auto"
              />
              <h3 className="text-center font-semibold group-hover/head:underline duration-300 ease-in cursor-pointer min-h-[3rem]">
                Search Engine Optimization (SEO)
              </h3>
              <p className="text-justify px-2 text-[.9rem]  pb-3">
                Enhance your online presence and divert more yet relevant
                traffic to your business website with our customized SEO
                strategies and services.
              </p>
              <a
                href="/search-engine-optimization"
                className="px-3 py-2 rounded-md text-white font-semibold bg-[#2186f9]"
              >
                Read More
              </a>
            </div>
            <div className="group/head min-h-[280px] w-[17rem] border border-[#FCD581] rounded-xl  hover:shadow-md hover:bg-[#e4fbd9] duration-300 ease-in text-center ">
              <img
                src={seoIcon}
                alt="seoIcon"
                className=" rounded-[50%] w-10 h-10 my-4 m-auto"
              />
              <h3 className="text-center font-semibold group-hover/head:underline duration-300 ease-in cursor-pointer min-h-[3rem]">
                Social Media Marketing (SMM)
              </h3>
              <p className="text-justify px-2 text-[.9rem]  pb-3">
                Enhance your online presence and divert more yet relevant
                traffic to your business website with our customized SEO
                strategies and services.
              </p>
              <a
                href="/social-media-marketing-services"
                className="px-3 py-2 rounded-md text-white font-semibold bg-[#2186f9]"
              >
                Read More
              </a>
            </div>
            <div className="group/head min-h-[280px] w-[17rem] border border-[#FCD581] rounded-xl  hover:shadow-md hover:bg-[#e4fbd9] duration-300 ease-in text-center ">
              <img
                src={seoIcon}
                alt="seoIcon"
                className=" rounded-[50%] w-10 h-10 my-4 m-auto"
              />
              <h3 className="text-center font-semibold group-hover/head:underline duration-300 ease-in cursor-pointer min-h-[3rem]">
                Pay-Per-Click (PPC)
              </h3>
              <p className="text-justify px-2 text-[.9rem]  pb-3">
                Enhance your online presence and divert more yet relevant
                traffic to your business website with our customized SEO
                strategies and services.
              </p>
              <a
                href="/ppc-ads-services"
                className="px-3 py-2 rounded-md text-white font-semibold bg-[#2186f9]"
              >
                Read More
              </a>
            </div>
            <div className="group/head min-h-[280px] w-[17rem] border border-[#FCD581] rounded-xl  hover:shadow-md hover:bg-[#e4fbd9] duration-300 ease-in text-center ">
              <img
                src={seoIcon}
                alt="seoIcon"
                className=" rounded-[50%] w-10 h-10 my-4 m-auto"
              />
              <h3 className="text-center font-semibold group-hover/head:underline duration-300 ease-in cursor-pointer min-h-[3rem]">
                Display Advertising
              </h3>
              <p className="text-justify px-2 text-[.9rem]  pb-3">
                Enhance your online presence and divert more yet relevant
                traffic to your business website with our customized SEO
                strategies and services.
              </p>
              <a
                href="/"
                className="px-3 py-2 rounded-md text-white font-semibold bg-[#2186f9]"
              >
                Read More
              </a>
            </div>
            <div className="group/head min-h-[280px] w-[17rem] border border-[#FCD581] rounded-xl  hover:shadow-md hover:bg-[#e4fbd9] duration-300 ease-in text-center ">
              <img
                src={seoIcon}
                alt="seoIcon"
                className=" rounded-[50%] w-10 h-10 my-4 m-auto"
              />
              <h3 className="text-center font-semibold group-hover/head:underline duration-300 ease-in cursor-pointer min-h-[3rem]">
                Content Marketing
              </h3>
              <p className="text-justify px-2 text-[.9rem]  pb-3">
                Enhance your online presence and divert more yet relevant
                traffic to your business website with our customized SEO
                strategies and services.
              </p>
              <a
                href="/"
                className="px-3 py-2 rounded-md text-white font-semibold bg-[#2186f9]"
              >
                Read More
              </a>
            </div>
            <div className="group/head min-h-[280px] w-[17rem] border border-[#FCD581] rounded-xl  hover:shadow-md hover:bg-[#e4fbd9] duration-300 ease-in text-center ">
              <img
                src={seoIcon}
                alt="seoIcon"
                className=" rounded-[50%] w-10 h-10 my-4 m-auto"
              />
              <h3 className="text-center font-semibold group-hover/head:underline duration-300 ease-in cursor-pointer min-h-[3rem]">
                Online Reputation System (ORS)
              </h3>
              <p className="text-justify px-2 text-[.9rem]  pb-3">
                Enhance your online presence and divert more yet relevant
                traffic to your business website with our customized SEO
                strategies and services.
              </p>
              <a
                href="/"
                className="px-3 py-2 rounded-md text-white font-semibold bg-[#2186f9]"
              >
                Read More
              </a>
            </div>
          </div>
        </div>
        <div className=" mt-8 lg:mt-16 section1 px-5">
          <h2 className="text-center text-[1.4rem] lg:text-[2rem] font-bold">
            Why choose your Business Digital marketing services{" "}
          </h2>
          <div className="mt-6 flex flex-row flex-wrap justify-center gap-3 items-center lg:px-24">
            <div className="group/head min-h-[220px] w-[19rem] lg:w-[17rem] border rounded-xl  hover:shadow-md hover:bg-[#d9fbf7] duration-300 ease-in text-center ">
              <img
                src={brandValuesIcon}
                alt="seoIcon"
                className=" w-10 h-10 my-4 m-auto"
              />
              <h3 className="text-center font-semibold group-hover/head:underline duration-300 ease-in cursor-pointer min-h-[2rem]">
                Support Brand Value
              </h3>
              <p className="text-center px-2 text-[.9rem]">
                Enhance your online presence and divert more yet relevant
                traffic to your business website with our customized SEO
                strategies and services.
              </p>
            </div>
            <div className="group/head min-h-[220px] w-[19rem] lg:w-[17rem] border rounded-xl  hover:shadow-md hover:bg-[#d9fbf7] duration-300 ease-in text-center ">
              <img
                src={socialMediaMarketingIcon}
                alt="seoIcon"
                className=" w-10 h-10 my-4 m-auto"
              />
              <h3 className="text-center font-semibold group-hover/head:underline duration-300 ease-in cursor-pointer min-h-[2rem]">
                Boost User Relationship
              </h3>
              <p className="text-center px-2 text-[.9rem]">
                Enhance your online presence and divert more yet relevant
                traffic to your business website with our customized SEO
                strategies and services.
              </p>
            </div>
            <div className="group/head min-h-[220px] w-[19rem] lg:w-[17rem] border rounded-xl  hover:shadow-md hover:bg-[#d9fbf7] duration-300 ease-in text-center ">
              <img
                src={userTrafficIcon}
                alt="seoIcon"
                className=" w-10 h-10 my-4 m-auto"
              />
              <h3 className="text-center font-semibold group-hover/head:underline duration-300 ease-in cursor-pointer min-h-[2rem]">
                Drive More Traffic
              </h3>
              <p className="text-center px-2 text-[.9rem]">
                Enhance your online presence and divert more yet relevant
                traffic to your business website with our customized SEO
                strategies and services.
              </p>
            </div>
            <div className="group/head min-h-[220px] w-[19rem] lg:w-[17rem] border rounded-xl  hover:shadow-md hover:bg-[#d9fbf7] duration-300 ease-in text-center ">
              <img
                src={badgeIcon}
                alt="seoIcon"
                className=" w-10 h-10 my-4 m-auto"
              />
              <h3 className="text-center font-semibold group-hover/head:underline duration-300 ease-in cursor-pointer min-h-[2rem]">
                Stay on Top
              </h3>
              <p className="text-center px-2 text-[.9rem]">
                Enhance your online presence and divert more yet relevant
                traffic to your business website with our customized SEO
                strategies and services.
              </p>
            </div>
            <div className="group/head min-h-[220px] w-[19rem] lg:w-[17rem] border rounded-xl  hover:shadow-md hover:bg-[#d9fbf7] duration-300 ease-in text-center ">
              <img
                src={financialCompetetor}
                alt="seoIcon"
                className=" w-10 h-10 my-4 m-auto"
              />
              <h3 className="text-center font-semibold group-hover/head:underline duration-300 ease-in cursor-pointer min-h-[2rem]">
                Increase in Competition
              </h3>
              <p className="text-center px-2 text-[.9rem]">
                Enhance your online presence and divert more yet relevant
                traffic to your business website with our customized SEO
                strategies and services.
              </p>
            </div>
            <div className="group/head min-h-[220px] w-[19rem] lg:w-[17rem] border rounded-xl  hover:shadow-md hover:bg-[#d9fbf7] duration-300 ease-in text-center ">
              <img
                src={conversionIcon}
                alt="seoIcon"
                className=" w-10 h-10 my-4 m-auto"
              />
              <h3 className="text-center font-semibold group-hover/head:underline duration-300 ease-in cursor-pointer min-h-[2rem]">
                Improve Conversion Rate
              </h3>
              <p className="text-center px-2 text-[.9rem]">
                Enhance your online presence and divert more yet relevant
                traffic to your business website with our customized SEO
                strategies and services.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-[5rem]">
          <FaqSectionComponent faqHeading={faqHeading} faqData={faqData} />
        </div>
      </div>
    </div>
  );
}

export default DigitalMarketingPageContent