import React from "react";
import ISO_ICON from "../../../media/iso.png";
import MSME_ICON from "../../../media/msme.png";
import ICSI_ICON from "../../../media/icsi.png";
import ASSOCHAM_ICON from "../../../media/assocham.png";
import STARTUPINDIA_ICON from "../../../media/startup-india.png";

function RecognitionComponent() {
  return (
    <div className="px-12 py-10 lg:py-12 lg:px-32">
      <div className="flex w-[100%] justify-center gap-2 lg:gap-8">
        <img
          src={ISO_ICON}
          className="w-[20%] lg:w-[14%] hover:transform hover:scale-110 transition-transform duration-300"
          alt="iso_icon"
        />
        <img
          src={MSME_ICON}
          className="w-[20%] lg:w-[14%] hover:transform hover:scale-110 transition-transform duration-300"
          alt="iso_icon"
        />
        <img
          src={ICSI_ICON}
          className="w-[20%] lg:w-[14%] hover:transform hover:scale-110 transition-transform duration-300"
          alt="iso_icon"
        />
        <img
          src={ASSOCHAM_ICON}
          className="w-[20%] lg:w-[14%] hover:transform hover:scale-110 transition-transform duration-300"
          alt="iso_icon"
        />
        <img
          src={STARTUPINDIA_ICON}
          className="w-[20%] lg:w-[14%] hover:transform hover:scale-110 transition-transform duration-300"
          alt="iso_icon"
        />
      </div>
    </div>
  );
}

export default RecognitionComponent;
