import React, { useEffect, useState } from "react";
import "../Components/OurTeams.css";
import { Link, useLocation } from "react-router-dom";
import Navbar from "../Components/CommonComponents/Navbar";
import SectionCard from "../Components/CommonComponents/SectionCard";
import NewFooterComponent from "../Components/CommonComponents/NewFooterComponent/NewFooterComponent";
import EmailIcon from "../media/email-icon.png";
import LinkedinIcon from "../media/linkedin-icon.png";
import team1 from "../media/team1.png";
import team2 from "../media/team2.png";
import team3 from "../media/team3.png";
import team4 from "../media/team4.png";
import team5 from "../media/team5.png";
import team6 from "../media/team6.png";
import team7 from "../media/team7.png";
import team8 from "../media/team8.png";
import team9 from "../media/team9.png";
import team10 from "../media/team10.png";
import team11 from "../media/team11.png";
import team12 from "../media/team12.png";
import team13 from "../media/team13.png";
import team14 from "../media/team14.png";
import Seo from "../Components/CommonComponents/Seo";

function PrivacyPolicy() {
  const [test, setTest] = useState(false);
  const isNavbarOpened = () => {
    setTest((prev) => !test);
  };

  const teamMembers = [
    {
      img: team1,
      name: "Alisha",
      role: "Chief Growth Officer",
      url: {
        email: "",
        linkedinUrl: "https://www.linkedin.com/in/alisha-915b68211/",
      },
    },
    {
      img: team2,
      name: "Shiv",
      role: "Chief Technical Officer",
      url: {
        email: "",
        linkedinUrl: "https://www.linkedin.com/in/shiv-gupta-771569118/",
      },
    },
    {
      img: team3,
      name: "Hitesh",
      role: "Chief Information Officer",
      url: {
        email: "",
        linkedinUrl: "https://www.linkedin.com/in/hitesh-kumar-a39105b9/",
      },
    },
    {
      img: team4,
      name: "Sucheta",
      role: "Senior Marketing Manager",
      url: {
        email: "",
        linkedinUrl: "https://www.linkedin.com/in/sucheta-chauhan/r",
      },
    },
    {
      img: team5,
      name: "Ashish",
      role: "Senior Marketing Manager",
      url: {
        email: "",
        linkedinUrl: "https://www.linkedin.com/in/ranklines/",
      },
    },
    {
      img: team6,
      name: "Adarsh",
      role: "Digital Marketing Executive",
      url: {
        email: "",
        linkedinUrl: "https://www.linkedin.com/in/adarsh-jaiswal-98a11a1b3/",
      },
    },
    {
      img: team7,
      name: "Ankur",
      role: "Digital Marketing Executive",
      url: {
        email: "",
        linkedinUrl: "https://www.linkedin.com/in/ankur-bara",
      },
    },
    {
      img: team8,
      name: "Abidi Yusha Yusuf",
      role: "Operations Head",
      url: {
        email: "",
        linkedinUrl: "https://www.linkedin.com/in/abidi-yusha-yusuf-349aa752",
      },
    },
    {
      img: team9,
      name: "Arun",
      role: "Web Developer",
      url: {
        email: "",
        linkedinUrl: "http://linkedin.com/in/arun-sharma-538b90226",
      },
    },
    {
      img: team10,
      name: "Nisha",
      role: "SEO Executive",
      url: {
        email: "",
        linkedinUrl: "https://www.linkedin.com/in/nisha-rani-14915a202/",
      },
    },
    {
      img: team11,
      name: "Saurabh",
      role: "Content Writer",
      url: {
        email: "",
        linkedinUrl: "https://www.linkedin.com/in/saurabh-jha-243b5b177",
      },
    },
    {
      img: team12,
      name: "Saurav",
      role: "Content Writer",
      url: {
        email: "",
        linkedinUrl: "https://www.linkedin.com/in/saurav-verma-71192a218/",
      },
    },
    {
      img: team13,
      name: "Shivani",
      role: "Data Analyst",
      url: {
        email: "",
        linkedinUrl: "http://www.linkedin.com/in/shivani8860",
      },
    },
    {
      img: team14,
      name: "Jatin",
      role: "Digital Marketing Executive",
      url: {
        email: "",
        linkedinUrl: "https://www.linkedin.com/in/jatin-kumar-1097a9229/",
      },
    },
  ];

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
      <Seo
        title={"Our Teams | Nextbigbox "}
        description={"Top It Solution in Delhi NCR."}
        name={"Nextbigbox"}
        type={"Article"}
      />
      {/* Navbar Start  */}
      <Navbar isNavbarOpened={isNavbarOpened} isHomePage={false} />
      {/* Navbar End  */}
      {/* Web Seo Main Component  */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <div className="our_teams_main">
          <h1>Our Teams</h1>
          <div>
            {teamMembers.map((member) => (
              <ul key={member.id}>
                <li>
                  <img src={member.img} alt="img" />
                  <p>{member.name}</p>
                  <p>
                    <b>{member.role}</b>
                  </p>
                  <ul className="socials">
                    <li>
                      <Link to="#">
                        <img src={EmailIcon} alt="img" />
                      </Link>
                    </li>
                    <li>
                      <Link to={member.url.linkedinUrl}>
                        <img src={LinkedinIcon} alt="img" />
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            ))}
          </div>
        </div>
      </SectionCard>
      {/* Web Seo  Main Component End */}
      {/* FooterSection Start */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <NewFooterComponent />
      </SectionCard>
      {/* FooterSection End */}
    </div>
  );
}

export default PrivacyPolicy;
