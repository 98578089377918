import React from "react";
import caseSudies1 from '../../media/case_studies_1.png'
import caseSudies2 from '../../media/case_studies_2.png' 
import caseSudies3 from '../../media/case_studies_3.png' 
import caseSudies4 from '../../media/case_studies_4.png' 
import caseSudies5 from '../../media/case_studies_5.png' 
import caseSudies6 from '../../media/case_studies_6.png' 
import caseSudies7 from '../../media/case_studies_7.png' 
import caseSudies8 from '../../media/case_studies_8.png' 
import caseSudies9 from '../../media/case_studies_9.png' 
import caseSudies10 from '../../media/case_studies_10.png' 
import caseSudies11 from '../../media/case_studies_11.png' 
import caseSudies12 from '../../media/case_studies_12.png' 
import { Link } from "react-router-dom";
import ContactComponent from "../CommonComponents/ContactComponent/ContactComponent";
import SliderComponent from "../CommonComponents/SliderComponent";
// import pdf from '../../media/portfolio.pdf'

function NewPortFolioPageComponent({ test }) {
  

  return (
    <div className="pt-[5rem] px-8">
      <h1 className="mt-4 text-5xl">Case Studies</h1>
      <div className="mt-8">
        <div className=" ">
          <div className=" flex flex-col lg:flex-row gap-8 mb-8">
            <img className=" w-100 lg:w-1/2" src={caseSudies6} alt="img" />
            <div className=" w-100 lg:w-1/2 mt-4">
              <h2 className="font-semibold text-[1.3rem] ">
                Fintech CRM : Elevates Loan Management System
              </h2>
              <p className="mt-4 mb-8">
                Fintech CRM: Which ensured the growth We have been working with
                multiple clients, especially those related to fintech and the
                emergence of new challenges always affected them reducing the
                efficiency. Subsequently, our customised fintech CRM and its
                advanced features helped them connect with the customers during
                the entire period. As per their opinion and experience, our
                fintech CRM helped them enhance their financial growth by up to
                50% which is indeed a positive sign for all those willing to
                integrate their fintech company with an efficient CRM. It is
                essential and beneficial for all the fintech companies,
                especially which has been established recently or are to be
                started because without having a robust CRM i.e., client
                relationship management, it is difficult to get a stop solution
                for the accomplishment of fintech services.
              </p>
              {/* <a
                href={pdf}
                rel="noopener noreferrer"
                className=" border px-4 py-3 rounded-md hover:bg-blue-200 transition-all duration-200"
                target="_blank"
              >
                View Complete Portfolio
              </a> */}
            </div>
          </div>
          <div className=" flex flex-col lg:flex-row-reverse gap-8 mb-8">
            <img className=" w-100 lg:w-1/2" src={caseSudies7} alt="img" />
            <div className=" w-100 lg:w-1/2 mt-4">
              <h2 className="font-semibold text-[1.3rem] ">
                E-Commerce Websites
              </h2>
              <p className="mt-4 mb-8">
                With the precise design and appropriate layout, our E-commerce
                website design and SEO services help you be ahead of the
                competitors.
              </p>
              {/* <a
                href={pdf}
                rel="noopener noreferrer"
                className=" border px-4 py-3 rounded-md hover:bg-blue-200 transition-all duration-200"
                target="_blank"
              >
                View Complete Portfolio
              </a> */}
            </div>
          </div>
          <div className=" flex flex-col lg:flex-row gap-8 mb-8">
            <img className=" w-100 lg:w-1/2" src={caseSudies8} alt="img" />
            <div className=" w-100 lg:w-1/2 mt-4">
              <h2 className="font-semibold text-[1.3rem] ">
                Digital Marketing
              </h2>
              <p className="mt-4 mb-8">
                Our specialization is there in full-fledged digital marketing
                including all its components like social media marketing, SEO,
                Email marketing, linked in Marketing etc.
              </p>
              {/* <a
                href={pdf}
                rel="noopener noreferrer"
                className="border px-4 py-3 rounded-md hover:bg-blue-200 transition-all duration-200"
                target="_blank"
              >
                View Complete Portfolio
              </a> */}
            </div>
          </div>
          <div className=" flex flex-col lg:flex-row-reverse gap-8 mb-8">
            <img className=" w-100 lg:w-1/2" src={caseSudies9} alt="img" />
            <div className=" w-100 lg:w-1/2 mt-4">
              <h2 className="font-semibold text-[1.3rem] ">HRMS Software</h2>
              <p className="mt-4 mb-8">
                We work for the HRMS software that can track the complete
                records of employees including attendance and other factors like
                leave and all.
              </p>
              {/* <a
                href={pdf}
                rel="noopener noreferrer"
                className=" border px-4 py-3 rounded-md hover:bg-blue-200 transition-all duration-200"
                target="_blank"
              >
                View Complete Portfolio
              </a> */}
            </div>
          </div>
          <div className=" flex flex-col lg:flex-row gap-8 mb-8">
            <img className=" w-100 lg:w-1/2" src={caseSudies10} alt="img" />
            <div className=" w-100 lg:w-1/2 mt-4">
              <h2 className="font-semibold text-[1.3rem] ">
                Sales CRM Software
              </h2>
              <p className="mt-4 mb-8">
                For the enhancement of your sales through the CRM we provide an
                advanced sales CRM that can accumulate all the data including
                leads, current status and all.
              </p>
              {/* <a
                href={pdf}
                rel="noopener noreferrer"
                className=" border px-4 py-3 rounded-md hover:bg-blue-200 transition-all duration-200"
                target="_blank"
              >
                View Complete Portfolio
              </a> */}
            </div>
          </div>
          <div className=" flex flex-col lg:flex-row-reverse gap-8 mb-8">
            <img className=" w-100 lg:w-1/2" src={caseSudies11} alt="img" />
            <div className=" w-100 lg:w-1/2 mt-4">
              <h2 className="font-semibold text-[1.3rem] ">Banking Services</h2>
              <p className="mt-4 mb-8">
                You can integrate your business with the banking services where
                we customize all these as per the services and requirements.
              </p>
              {/* <a
                href={pdf}
                rel="noopener noreferrer"
                className=" border px-4 py-3 rounded-md hover:bg-blue-200 transition-all duration-200"
                target="_blank"
              >
                View Complete Portfolio
              </a> */}
            </div>
          </div>
          <div className=" flex flex-col lg:flex-row gap-8 mb-8">
            <img className=" w-100 lg:w-1/2" src={caseSudies12} alt="img" />
            <div className=" w-100 lg:w-1/2 mt-4">
              <h2 className="font-semibold text-[1.3rem] ">
                Artificial Intelligence & Meta
              </h2>
              <p className="mt-4 mb-8">
                We have been working on AI-enabled services for automated
                services so that you can get a fast response from the customers
                willing to visit your site.
              </p>
              {/* <a
                href={pdf}
                rel="noopener noreferrer"
                className=" border px-4 py-3 rounded-md hover:bg-blue-200 transition-all duration-200"
                target="_blank"
              >
                View Complete Portfolio
              </a> */}
            </div>
          </div>
        </div>
        <ContactComponent />
      </div>
    </div>
  );
}

export default NewPortFolioPageComponent;
