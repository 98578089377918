import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../Components/CommonComponents/Navbar";
import SectionCard from "../Components/CommonComponents/SectionCard";
import LocalSeoContent from "../Components/LocalSeoContent/LocalSeoContent";
import NewFooterComponent from "../Components/CommonComponents/NewFooterComponent/NewFooterComponent";
import Seo from "../Components/CommonComponents/Seo";

function WebSeo() {
  const [test, setTest] = useState(false);
  const isNavbarOpened = () => {
    setTest((prev) => !test);
  };

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
      <Seo
        name={"Nextbigbox"}
        type={"Article"}
        title={"Best Local Seo Services | Local Search Engine Optimization"}
        description={
          "We Provide Best Local Seo Services and this process improving your business search visibility, traffic, and brand awareness for local businesses."
        }
        canonical_url="https://www.nextbigbox.io/local-seo-services"
        metaKeywords={[
          "local SEO Services",
          "Local Search Engine Optimization",
          "Local SEO",
          "SEO",
          "Search Engine Optimization",
        ]}
      />
      {/* Navbar Start  */}
      <Navbar isNavbarOpened={isNavbarOpened} isHomePage={false} />
      {/* Navbar End  */}
      {/* Web Seo Main Component  */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <LocalSeoContent />
      </SectionCard>
      {/* Web Seo  Main Component End */}
      {/* FooterSection Start */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <NewFooterComponent />
      </SectionCard>
      {/* FooterSection End */}
    </div>
  );
}

export default WebSeo;
