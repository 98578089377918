import { React, useState, useEffect } from "react";
import Navbar from "../Components/CommonComponents/Navbar";
import growBusiness from "../media/business-growth.webp";
import localVisibility from "../media/local search.webp";
import LmsHeroImg from "../media/lms_hero_image.webp";
import PageCard from "../Components/CommonComponents/PageCardComponent/PageCard";
import FaqSectionComponent from "../Components/CommonComponents/FaqSectionComponent/FaqSectionComponent";
import NewFooterComponent from "../Components/CommonComponents/NewFooterComponent/NewFooterComponent";

const DigitalMarketingAgency = () => {
  const [test, setTest] = useState(false);
  const isNavbarOpened = () => {
    setTest((prev) => !test);
  };

  const digitalAgencyServicesList = [
    {
      title: "Search engine optimization",
      description: (
        <>
          NextBigBox is the leading <strong>search engine optimization</strong>{" "}
          agency in India, with decades of experience in boosting online
          business visibility while driving organic traffic. We deliver
          exceptional results to our clients, helping them achieve their settled
          business goals with our customised strategies.
        </>
      ),
    },
    {
      title: "Social media optimization",
      description: (
        <>
          In this era when one-third of the world’s population is on social
          media, we consider top social media sites like Facebook, Instagram,
          Youtube, and so on to bring noticeable growth to your online business.{" "}
          <strong>Social media optimization</strong> helps increase your brand’s
          awareness among people around the world.
        </>
      ),
    },
    {
      title: " Pay per Click",
      description: (
        <>
          {" "}
          We have our specialised team to run effective{" "}
          <strong>pay per click</strong> campaigns. When you connect with us for
          PPC services, you will receive a different kind of peace of mind
          knowing that your PPC campaign is in the hands of Google Ads experts
          who understand how to deal with complexity.
        </>
      ),
    },
    {
      title: "Branding",
      description: (
        <>
          We consider branding as a way of developing effective brand awareness.
          With public relations and social media, it creates its own identity in
          the market. With our top-notch <strong>branding</strong> strategies,
          we can easily reach your target audience and attract them to your
          business.
        </>
      ),
    },
    {
      title: "Social media marketing",
      description: (
        <>
          Let us boost the online presence of your business with our highly
          profitable <strong>social media marketing services</strong>. Our
          experts have extensive knowledge of managing social media posts,
          running relatable ads, regularly analysing results, and more. The top
          social media platforms, like Facebook, Instagram, Twitter, and more,
          always stay on our target list to attract potential visitors to the
          website.
        </>
      ),
    },
    {
      title: "Content marketing",
      description: (
        <>
          Our dedicated team of content marketers is here to create and share
          relatable and valuable content that grabs a large audience. The
          content we deliver includes blogs, social media captions, videos, and
          more. Our <strong>content marketing</strong> services focus on
          offering as much information to the audience as is required to
          generate unbreakable trust, drive traffic, and support your business’s
          growth
        </>
      ),
    },
    {
      title: "Web design and development",
      description: (
        <>
          Having a visually appealing website is very important for any business
          to strengthen their online business. Our{" "}
          <strong>web design and development</strong> services include building
          a user-friendly, functional, and attractive website. Our web designers
          and developers focus on deciding layouts, colours, coding, and
          addressing each technical aspect to enhance the considerable traffic
          to the website.
        </>
      ),
    },
    {
      title: "Local SEO services",
      description: (
        <>
          {" "}
          We serve the best <strong>local SEO services</strong> to our
          customers, which makes them visible at the top when customers try to
          find nearby services. We understand the technical aspects that help in
          managing online listings and building local backlinks that ultimately
          result in an improvement in your search rankings.{" "}
        </>
      ),
    },
    {
      title: " Ecommerce SEO services",
      description: (
        <>
          If you have an online store, allow us to improve its visibility in
          search results with our <strong>ecommerce SEO services</strong>. Our
          experts optimise each and every page of your online store and make
          changes wherever required. Plus, we deliver engaging content that
          attracts traffic to the business and increases its Google ranking.
        </>
      ),
    },
    {
      title: "Enterprise SEO services",
      description: (
        <>
          We can potentially improve the search engine rankings of large
          organisations. Our <strong>enterprise SEO services</strong> include
          site structure optimization, strategic content creation, and
          maintaining the technical aspects of SEO for improved online
          visibility. Our SEO team puts all their efforts into maintaining the
          consistency of the brand's higher rankings while driving the most
          traffic.
        </>
      ),
    },
    {
      title: "Email marketing services",
      description: (
        <>
          Let us help you take your business to new heights with our
          professional <strong>email marketing services</strong>. We are known
          to offer tailored and relevant email campaigns that tend to maximise
          ROI. We nurture leads, increase conversions, and help build stronger
          customer relationships.
        </>
      ),
    },
    {
      title: "Linkedin marketing services",
      description: (
        <>
          We have the best <strong>Linkedin marketing services</strong> to offer
          you the best use of this platform. We drive meaningful connections
          with an improved professional presence on LinkedIn, which ultimately
          makes your brand stand out in this competitive marketplace. So, you
          will be able to generate more leads with an expanded online network.
        </>
      ),
    },
  ];
  const faqData = [
    {
      q: "Can a digital marketing agency drive me traffic in Delhi?",
      a: "Yes, it can. Digital marketing agencies tend to bring traffic to online businesses around the world. It drives the growth of your business and brings favourable results.",
    },
    {
      q: "How do I identify the best digital marketing agency in Delhi?",
      a: "The best digital marketing agency in Delhi offers an exclusive range of services and always tends to use result-driven approaches. We offer the best digital marketing services, including SEO, SEM, SMO, content marketing, and so on.",
    },
    {
      q: "Does your digital marketing agency offer customised services in New Delhi?",
      a: "Certainly! All of our digital marketing approaches are tailored to meet the specific needs of our clients. Our professional team has expertise in every business that allows them to offer customised digital marketing services in New Delhi. ",
    },
    {
      q: "Can I expect the best local SEO from your digital marketing agency? ",
      a: "Absolutely. We have a dedicated team of local SEO experts to help your online store rank higher when someone searches nearby. This way, your business will be visible at the top in and around Delhi. ",
    },
  ];
  const faqHeading = "Digital Marketing Agency in Delhi";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar isNavbarOpened={isNavbarOpened} isHomePage={false} />
      <div className={test ? "hidden" : "visible"}>
        <div className="flex flex-row justify-center items-center w-full h-56 mb-20 pt-[4rem] bg-gradient-to-b from-sky-400 to-white">
          <h1 className="font-bold text-[2.4rem] text-center mr-[2rem] ml-[2rem] lg:mx-[5.3rem]">
            Experienced Digital Marketing Agency
          </h1>
        </div>
        <div className=" px-[2rem] lg:px-[5.3rem]">
          <section>
            <div className="w-full flex flex-wrap-reverse md:flex-nowrap gap-6 ">
              <div className="w-full md:w-1/2 " data-aos="zoom-in">
                <p className="text-justify ">
                  When everything is moving to digital, it is very important to
                  have a strong online presence for businesses to succeed.
                  NextBigBox is a dedicated <b>digital marketing agency</b> that
                  helps online businesses achieve their goals in the market. Our
                  team has an in-depth understanding of the latest trends and
                  technologies, which are essential to creating the best
                  innovative methods. We believe in working with the three
                  methods below:
                </p>
                <ul className="list-disc list-inside mt-10">
                  <li className="m-2">
                    <b>Analysis of Actual Data: </b>Data-driven insights help in
                    shaping effective campaigns.
                  </li>
                  <li className="m-2">
                    <b>Client-Centric Strategy: </b>We tailor our strategies to
                    the customer's requirements.
                  </li>
                  <li className="m-2">
                    <b>High-end Technology:</b> We use the latest tools to
                    maximise profitable impact.{" "}
                  </li>
                </ul>
                <p className="mt-10 text-justify">
                  Be it boosting brand awareness, driving an enhanced number of
                  people to the website, or converting potential customers into
                  actual ones, we never fail to deliver you beneficial results.
                  So, let us help you improve your brand’s presence in this
                  competitive digital landscape.
                </p>
              </div>
              <div className="flex flex-row justify-center items-center w-full md:w-1/2">
                <img src={LmsHeroImg} alt="img" width={"500px"} />
              </div>
            </div>
          </section>

          <section>
            <h2 className="mb-14 mt-20 font-bold text-[1.8rem] text-center ">
              The Top Digital Marketing Agency You Can Trust
            </h2>
            <div className="w-full flex flex-wrap md:flex-nowrap gap-10">
              <div className="flex flex-row justify-center items-center w-full md:w-1/2">
                <img src={growBusiness} alt="img" />
              </div>
              <div className="flex flex-row justify-center items-center w-full md:w-1/2 text-justify ">
                <div data-aos="zoom-in">
                  <p>
                    NextBigBox is a reputed digital marketing agency with a
                    proven track record of serving the best results in improving
                    online presence. Being the most experienced and{" "}
                    <b>top digital marketing agency</b>, we believe in using
                    proven strategies that potentially meet your business goals.
                    We ensure every aspect of the strategy you developed is
                    perfectly optimised.
                  </p>
                  <p className="mt-8">
                    Our experts bring potential customers to your websites by
                    making crucial changes to the existing content along with
                    eye-catching web design. Connecting with us for the growth
                    of your brand will deliver you favourable results. Hence,
                    experience the difference of having a top digital marketing
                    agency by your side and get high-end results.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section>
            <h2 className="mb-4 mt-24 font-bold text-[1.8rem] text-center ">
              Best Digital Marketing Agency
            </h2>
            <div className="w-full flex flex-wrap-reverse md:flex-nowrap gap-10">
              <div className="flex flex-row justify-center items-center w-full md:w-1/2  text-justify">
                <div data-aos="zoom-in">
                  <p>
                    We transform the online presence of your business with our
                    innovative strategies. Our core focus is to convert visitors
                    into potential customers. As NextBigBox is the
                    <b> best digital marketing agency</b> in your area, we have
                    a dedicated team of industry experts who deliver
                    unbelievable results. Our strategies have been made in a way
                    that makes your brand stand out and attractive. We ensure
                    taking your business to new heights, even at the most
                    competitive rates.
                  </p>
                </div>
              </div>
              <div className="flex flex-row justify-center items-center w-full md:w-1/2">
                <img className="w-[450px]" src={localVisibility} alt="img" />
              </div>
            </div>
          </section>

          <section>
            <h2 className="mt-24 mb-6 font-bold text-[1.8rem] text-center ">
              The best services for a digital marketing agency in India
            </h2>
            <p className="text-center">
              Get all the digital marketing services at a single place. Here is
              the expanded form of our top digital marketing services in India,
              available at a reasonable price. Let’s explore more:
            </p>
            <div className="flex flex-wrap mt-20 mb-20 gap-4 justify-center">
              {digitalAgencyServicesList.map((data, index) => (
                <PageCard
                  key={index}
                  title={data.title}
                  content={data.description}
                />
              ))}
            </div>
          </section>
        </div>
        <FaqSectionComponent faqData={faqData} faqHeading={faqHeading} />
        <NewFooterComponent />
      </div>
    </div>
  );
};

export default DigitalMarketingAgency;
