import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../Components/CommonComponents/Navbar";
import NewFooterComponent from "../Components/CommonComponents/NewFooterComponent/NewFooterComponent";
import SectionCard from "../Components/CommonComponents/SectionCard";
import CrmPageContent from "../Components/CrmPageContent/CrmPageContent";
import Seo from "../Components/CommonComponents/Seo";
import DigitalMarketingPageContent from "../Components/DigitalMarketingPageContent";

function DigitalMarketingPage() {
  const [test, setTest] = useState(false);
  const isNavbarOpened = () => {
    setTest((prev) => !test);
  };
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <div className="">
      <Seo
        title={"Digital Marketing | Nextbigbox "}
        description={"Top It Solution in Delhi NCR."}
        name={"Nextbigbox"}
        type={"Article"}
      />
      {/* Contatc Page Started  */}
      {/* Navbar Started  */}
      <Navbar isNavbarOpened={isNavbarOpened} isHomePage={false} />
      {/* Navbar End  */}

      {/* Crm content Started */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-auto"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <DigitalMarketingPageContent />
      </SectionCard>
      {/* Crm content End */}

      {/* Footer Started  */}
      <SectionCard
        position={"relative"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800"}
        visible={test ? "hidden" : "show"}
      >
        <NewFooterComponent />
      </SectionCard>
      {/* Footer End  */}
      {/* Contact Page End  */}
    </div>
  );
}

export default DigitalMarketingPage;
