import React from "react";

export default function Box({ index, item }) {
    switch (item._type) {
        case 0:
            return (
                <div className="my-3 w-fit rounded bg-black px-2 py-1.5 shadow transition-all duration-500">
                    <span
                        className={`text-sm font-semibold ${
                            item.color === "blue" ? "text-blue-500" : ""
                        }${item.color === "red" ? "text-red-500" : ""}${
                            item.color === "yellow" ? "text-yellow-500" : ""
                        }`}
                    >
                        {item.text}
                    </span>
                </div>
            );

        case 1:
            return (
                <div className="my-3 flex w-fit flex-col rounded bg-black px-2 py-1.5 shadow transition-all duration-500">
                    <span
                        className={`text-sm font-semibold ${
                            item.color === "blue" ? "text-blue-500" : ""
                        }${item.color === "red" ? "text-red-500" : ""}${
                            item.color === "yellow" ? "text-yellow-500" : ""
                        }`}
                    >
                        {item.text}
                    </span>
                    <span className="font-mono text-xs font-extralight text-white">
                        {item.subText}
                    </span>
                </div>
            );

        case 2:
            return (
                <div className="my-3 flex w-fit items-center space-x-2 rounded bg-white px-2 py-1.5 shadow transition-all duration-500">
                    <span
                        className={`relative h-4 w-4 rounded-full ${
                            item.color === "red" ? "bg-red-500" : ""
                        }${item.color === "green" ? "bg-green-500" : ""}${
                            item.color === "yellow" ? "bg-yellow-500" : ""
                        }`}
                    ></span>
                    <span className="text-sm">{item.text}</span>
                </div>
            );

        case 3:
            return (
                <div className="my-3 flex w-fit items-center space-x-2 rounded bg-white px-2 py-1.5 shadow transition-all duration-500">
                    <span className={`relative h-4 w-4 rounded-full`}>
                        {item.icon}
                    </span>
                    <span className="font-mono text-xs">{item.text}</span>
                </div>
            );

        default:
            break;
    }
}
