import React, { useEffect } from "react";
import Header from "./Header";
import Box from "./Box";

export default function BannerBox() {
    const data = [
        {
            _type: 0,
            text: "GET",
            color: "blue",
        },
        {
            _type: 0,
            text: "GET",
            color: "red",
        },
        {
            _type: 0,
            text: "GET",
            color: "yellow",
        },
        {
            _type: 1,
            text: "GET",
            color: "blue",
            subText: "{request.body}",
        },
        {
            _type: 2,
            text: "404",
            color: "red",
        },
        {
            _type: 2,
            text: "404",
            color: "yellow",
        },
        {
            _type: 2,
            text: "404",
            color: "green",
        },
        {
            _type: 3,
            text: "Try It",
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-full w-full"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                    />
                </svg>
            ),
        },
    ];
    const [boxdata, setBoxdata] = React.useState({
        box1: [
            {
                _type: 0,
                text: "GET",
                color: "blue",
            },
            {
                _type: 0,
                text: "GET",
                color: "red",
            },
            {
                _type: 0,
                text: "GET",
                color: "yellow",
            },
            {
                _type: 1,
                text: "GET",
                color: "blue",
                subText: "{request.body}",
            },
            {
                _type: 2,
                text: "404",
                color: "red",
            },
            {
                _type: 2,
                text: "404",
                color: "yellow",
            },
            {
                _type: 2,
                text: "201",
                color: "green",
            },
            {
                _type: 3,
                text: "Try It",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="h-full w-full"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                        />
                    </svg>
                ),
            },
        ],
        box2: [
            {
                _type: 0,
                text: "GET",
                color: "blue",
            },
            {
                _type: 0,
                text: "GET",
                color: "red",
            },
            {
                _type: 0,
                text: "GET",
                color: "yellow",
            },
            {
                _type: 1,
                text: "GET",
                color: "blue",
                subText: "{request.body}",
            },
            {
                _type: 2,
                text: "404",
                color: "red",
            },
            {
                _type: 2,
                text: "404",
                color: "yellow",
            },
            {
                _type: 2,
                text: "404",
                color: "green",
            },
            {
                _type: 3,
                text: "Try It",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="h-full w-full"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                        />
                    </svg>
                ),
            },
        ],
        box3: [
            {
                _type: 0,
                text: "GET",
                color: "blue",
            },
            {
                _type: 0,
                text: "GET",
                color: "red",
            },
            {
                _type: 0,
                text: "GET",
                color: "yellow",
            },
            {
                _type: 1,
                text: "GET",
                color: "blue",
                subText: "{request.body}",
            },
            {
                _type: 2,
                text: "404",
                color: "red",
            },
            {
                _type: 2,
                text: "404",
                color: "yellow",
            },
            {
                _type: 2,
                text: "404",
                color: "green",
            },
            {
                _type: 3,
                text: "Try It",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="h-full w-full"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                        />
                    </svg>
                ),
            },
        ],
    });
    useEffect(() => {
        setTimeout(() => {
            const boxNameList = ["box1", "box2", "box3"];
            const currentBox =
                boxNameList[Math.floor(Math.random() * boxNameList.length)];
            var hereBoxData = boxdata[currentBox];
            const newItem = data[Math.floor(Math.random() * data.length)];
            hereBoxData.splice(hereBoxData.length - 1);
            hereBoxData.unshift(newItem);
            setBoxdata({ ...boxdata, currentBox: hereBoxData });
        }, 2000);
    });

    return (
        <div className="relative w-full max-w-xl rounded-tl-lg border-4  h-[92%] border-blue-950 overflow-hidden">
            <Header />

            {/* content */}
            <div className="relative h-full w-full">
                {/* backdrop */}
                <div className="relative">
                    <img src="bannerBoxBackdrop.webp" alt="" />
                </div>
                {/* main box */}
                <div className="absolute left-0 top-0 grid h-full w-full grid-cols-3 place-items-center overflow-hidden">
                    {/* box 1 */}
                    <div className="relative h-full w-[80%]">
                        <div className="absolute left-5 top-0 h-full w-1.5 ">
                            <div className="h-20 w-full bg-gradient-to-t from-blue-300 to-transparent" />
                            <div className="h-[95%] overflow-hidden w-full bg-blue-300" />
                        </div>
                        <div className="relative transition-all">
                            <div className="relative mb-10 mt-10 h-10 w-10 translate-x-0.5 rounded-full border-2 border-gray-600 bg-blue-300"></div>
                            {boxdata.box1.map((item, index) => (
                                <Box key={index} index={index} item={item} />
                            ))}
                        </div>
                    </div>
                    <div className="relative h-full w-[80%]">
                        <div className="absolute left-5 top-0 h-full w-1.5 ">
                            <div className="h-20 w-full bg-gradient-to-t from-blue-300 to-transparent" />
                            <div className="h-[95%] overflow-hidden w-full bg-blue-300" />
                        </div>
                        <div className="relative translate-y-0">
                            <div className="relative mb-10 mt-10 h-10 w-10 translate-x-0.5 rounded-full border-2 border-gray-600 bg-blue-300"></div>
                            {boxdata.box2.map((item, index) => (
                                <Box key={index} index={index} item={item} />
                            ))}
                        </div>
                    </div>
                    <div className="relative h-full w-[80%]">
                        <div className="absolute left-5 top-0 h-full w-1.5 ">
                            <div className="h-20 w-full bg-gradient-to-t from-blue-300 to-transparent" />
                            <div className="h-[95%] overflow-hidden w-full bg-blue-300" />
                        </div>
                        <div className="relative translate-y-0">
                            <div className="relative mb-10 mt-10 h-10 w-10 translate-x-0.5 rounded-full border-2 border-gray-600 bg-blue-300"></div>
                            {boxdata.box3.map((item, index) => (
                                <Box key={index} index={index} item={item} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
