import React from "react";
import callIcon from "../../../media/call-button-icon.svg";
import chatIcon from "../../../media/chat-icon.png";
import emailButtonIcon from "../../../media/email-button-icon.svg";
import arrowIcon from "../../../media/arrow-icon.svg";
import facebookIcon from "../../../media/facebook-icon.svg";
import instagramIcon from "../../../media/instagram-icon.svg";
import youtubeIcon from "../../../media/youtube-icon.svg";
import emogiIcon from "../../../media/emogi-icon.svg";
import linkedinIcon from "../../../media/linkedin-icon.svg";
import skypeIcon from "../../../media/skype-icon.png";
import whatsappIcon from "../../../media/whatsapp_icon.png";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useForm } from "react-hook-form";
import axios from "axios";
import { FormGroup } from "reactstrap";

function NewFooterComponent() {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const onSubmit = async (data) => {
    console.log(data);
    // const res = await axios.post(
    //   "https://salescrm.nextbigbox.tech/api/addEmailer",
    //   data,
    //   {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   }
    // );
    if (1) {
      showToast("success");
      reset();
    } else {
      showToast("error");
    }
  };

  // toast message
  const showToast = (status) => {
    switch (status) {
      case "success":
        toast.success("Emailer Subscribed Successfully.", {
          position: "top-right",
          autoClose: 1000, // Close the toast after 1 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        break;
      case "error":
        toast.error("Something Went Wrong.", {
          position: "top-right",
          autoClose: 1000, // Close the toast after 1 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        break;
      default:
    }
  };
  // current year
  let currentYear = new Date().getFullYear();
  return (
    <div className="mt-10 p-6 overflow-hidden">
      <div className="border rounded-xl px-8 pt-8 bg-[#ddd]">
        <div className="m-4 flex flex-col lg:flex-row  justify-center gap-4 border-b-2 border-[#bfbfbf] pb-5">
          <div className="lg:w-[25%]">
            <p className="text-xl font-bold font-oswald">
              Get in touch with us
            </p>
            <p className="text-[.8rem] font-montserrat">
              Navigate with Digital Landscape with Us
            </p>
          </div>
          <div className="flex justify-start gap-1 lg:w-[25%] mt-2">
            {/* <img src={callIcon} className="w-9 " alt="icons" /> */}
            {/* <Link to="tel:918709264527">
              <p className="text-[.9rem]">Give us a Call</p>
              <p className="text-[.9rem] font-bold">1800-123-120555</p>
            </Link> */}
          </div>
          <div className="flex justify-start gap-1 lg:w-[25%] lg:pl-[8rem] mt-2">
            <img src={emailButtonIcon} className="w-10 -mt-4" alt="icons" />
            <Link to="mailto:reachus@nextbigbox.io">
              <p className="text-[.9rem]">Sent us a email</p>
              <p className="text-[.9rem] font-bold">reachus@nextbigbox.io</p>
            </Link>
          </div>
          <div className="flex justify-start gap-1 lg:w-[25%]  lg:pl-[8rem] ml-3 mt-2">
            {/* <div className="mt-3 h-6 rounded-[50%] bg-[#fbfbfb] p-[6px]">
              <img src={chatIcon} className="w-3" alt="icons" />
            </div> */}
            <div>
              <p className="text-[.9rem]">Chat with us</p>
              <div className="flex flex-row justify-start gap-2 mt-1">
                <a
                  href="https://join.skype.com/invite/vtlQoo4oa5ox"
                  target="blank"
                >
                  <img src={skypeIcon} className="w-4" alt="skype-icon" />
                </a>
                <a href="https://wa.me/9697959896?text=Hello" target="blank">
                  <img src={whatsappIcon} className="w-4" alt="whatsapp-icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="m-4 pl-3 md:pl-0 flex flex-col text-start lg:text-left lg:flex-row justify-between gap-4 font-montserrat pb-5 border-[#bfbfbf] border-b-2">
            <ul>
              <li className="font-bold text-[.8rem]">Services</li>
              <li className="pt-1 text-[.8rem] hover:font-[400] hover:underline transition-all duration-300 cursor-pointer">
                <Link to="/search-engine-optimization">SEO</Link>
              </li>
              <li className="pt-1 text-[.8rem] hover:font-[400] hover:underline transition-all duration-300 cursor-pointer">
                <Link to="/google-ads-services">Google Ads</Link>
              </li>
              {/* <li className="pt-1 text-[.8rem] hover:font-[400] hover:underline transition-all duration-300 cursor-pointer">
                <Link to="/social-media-marketing-services">Meta Ads</Link>
              </li> */}
              <li className="pt-1 text-[.8rem] hover:font-[400] hover:underline transition-all duration-300 cursor-pointer">
                <Link to="/local-seo-services">Local SEO</Link>
              </li>
              <li className="pt-1 text-[.8rem] hover:font-[400] hover:underline transition-all duration-300 cursor-pointer">
                <Link to="/ecommerce-seo-services">Ecommerce SEO</Link>
              </li>
              {/* <li className="pt-1 text-[.8rem] hover:font-[400] hover:underline transition-all duration-300 cursor-pointer">
                <Link to="/digital-marketing">Digital Marketing</Link>
              </li> */}
            </ul>
            {/* Country */}
            {/* <ul>
              <li className="font-bold text-[.8rem]">Country</li>
              <li className="pt-1 text-[.8rem] hover:font-[400] hover:underline transition-all duration-300 cursor-pointer">
                <Link to="/India">India</Link>
              </li>
              <li className="pt-1 text-[.8rem] hover:font-[400] hover:underline transition-all duration-300 cursor-pointer">
                <Link to="/Usa">Usa</Link>
              </li>
              <li className="pt-1 text-[.8rem] hover:font-[400] hover:underline transition-all duration-300 cursor-pointer">
                <Link to="/Uk">Uk</Link>
              </li>

              <li className="pt-1 text-[.8rem] hover:font-[400] hover:underline transition-all duration-300 cursor-pointer">
                <Link to="/Dubai">Dubai</Link>
              </li>
              <li className="pt-1 text-[.8rem] hover:font-[400] hover:underline transition-all duration-300 cursor-pointer">
                <Link to="/Singapore">Singapore</Link>
              </li>
            </ul> */}
            {/* Country */}
            {/* <li className="pt-1 text-[.8rem] hover:font-[400] hover:underline transition-all duration-300 cursor-pointer">
                <Link to="/social-media-marketing-services">Meta Ads</Link>
              </li> */}
            {/* <li className="pt-1 text-[.8rem] hover:font-[400] hover:underline transition-all duration-300 cursor-pointer">
                <Link to="/digital-marketing">Digital Marketing</Link>
              </li> */}
            <ul>
              <li className="font-bold text-[.8rem]">Solutions</li>
              <li className="pt-1 text-[.8rem] hover:font-[400] hover:underline transition-all duration-300 cursor-pointer">
                <Link to="/fintech-crm">Fintech CRM</Link>
              </li>
              <li className="pt-1 text-[.8rem] hover:font-[400] hover:underline transition-all duration-300 cursor-pointer">
                <Link to="/hrms">HRMS</Link>
              </li>
              <li className="pt-1 text-[.8rem] hover:font-[400] hover:underline transition-all duration-300 cursor-pointer">
                <Link to="/fintech-solution">Fintech Solutions</Link>
              </li>
              <li className="pt-1 text-[.8rem] hover:font-[400] hover:underline transition-all duration-300 cursor-pointer">
                <Link to="/web-development">Web Development</Link>
              </li>
              <li className="pt-1 text-[.8rem] hover:font-[400] hover:underline transition-all duration-300 cursor-pointer">
                <Link to="/api-integration">API</Link>
              </li>
            </ul>
            <ul>
              <li className="font-bold text-[.8rem]">Company</li>
              <li className="pt-1 text-[.8rem] hover:font-[400] hover:underline transition-all duration-300 cursor-pointer">
                <Link to="/About">About Us</Link>
              </li>
              <li className="pt-1 text-[.8rem] hover:font-[400] hover:underline transition-all duration-300 cursor-pointer">
                <Link to="/news-and-events">News & Events</Link>
              </li>
              <li className="pt-1 text-[.8rem] hover:font-[400] hover:underline transition-all duration-300 cursor-pointer">
                <Link to="/blogs">Blogs</Link>
              </li>
              <li className="pt-1 text-[.8rem] hover:font-[400] hover:underline transition-all duration-300 cursor-pointer">
                <Link to="/portfolio">Portfolio</Link>
              </li>
              <li className="pt-1 text-[.8rem] hover:font-[400] hover:underline transition-all duration-300 cursor-pointer">
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
            <ul>
              <li className="font-bold text-[.8rem]">Quick Links</li>
              <li className="pt-1 text-[.8rem] hover:font-[400] hover:underline transition-all duration-300 cursor-pointer">
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li className="pt-1 text-[.8rem] hover:font-[400] hover:underline transition-all duration-300 cursor-pointer">
                <Link to="/terms-and-conditions">Terms & Conditions</Link>
              </li>
              <li className="pt-1 text-[.8rem] hover:font-[400] hover:underline transition-all duration-300 cursor-pointer">
                <Link to="/cancellation-and-refund-policy">Refund Policy</Link>
              </li>
              <li className="pt-1 text-[.8rem] hover:font-[400] hover:underline transition-all duration-300 cursor-pointer">
                <Link to="/careers">Career</Link>
              </li>
              <li className="pt-1 text-[.8rem] hover:font-[400] hover:underline transition-all duration-300 cursor-pointer">
                <Link to="/case-studies">Case Studies</Link>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div className="m-4 flex flex-col lg:flex-row justify-between gap-4 font-montserrat pb-5 border-[#bfbfbf] border-b-2">
            <div className="">
              <p className="text-xl font-bold">
                Important updates waiting for you
              </p>
              <p className="text-[.8rem]">
                Don't miss the market updates on digital sector
              </p>
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="border w-[100%] lg:w-auto rounded-md flex items-center justify-center bg-white"
            >
              <FormGroup>
                <input
                  {...register("email", {
                    required: "Email id is Required.",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address.",
                    },
                  })}
                  placeholder="Enter your email address"
                  className="bg-white rounded-md w-[80%] lg:w-auto px-6 mt-3 focus:outline-none "
                  aria-invalid={errors.email ? "true" : "false"}
                />
                {errors.email?.message && (
                  <p role="alert" className="form-error px-4">
                    {errors.email?.message}
                  </p>
                )}
              </FormGroup>
              <button type="submit">
                <img
                  src={arrowIcon}
                  className="w-8 bg-white border-l-2 border-[gray] mx-3 pl-2"
                  alt="arrow-icons"
                />
              </button>
            </form>
          </div>
        </div>
        <div className="m-4 flex flex-col lg:flex-row text-center lg:text-left justify-between gap-4 font-montserrat">
          <p className="text-[.7rem]">
            {" "}
            &copy; Copyright {currentYear} Nextbigbox Pvt. Ltd. , India.
          </p>
          <ul className="flex gap-3 justify-center lg:justify-normal">
            <li>
              <a
                href="https://www.facebook.com/Nextbigbox/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={facebookIcon} alt="facebook_icon" className="w-4" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/nextbigboxindia/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={instagramIcon} alt="instagram_icon" className="w-4" />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/nextbigbox/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={linkedinIcon} alt="linkedin_icon" className="w-4" />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@nextbigbox"
                target="_blank"
                rel="noreferrer"
              >
                <img src={youtubeIcon} alt="youtube_icon" className="w-4" />
              </a>
            </li>
          </ul>
          <div className="flex justify-center lg:justify-normal">
            <img src={emogiIcon} alt="smile-emogie" className="w-4" />
            <p className="text-[.7rem]">Digital Dreams to Reality</p>
          </div>
        </div>
      </div>
      {/* <ToastContainer
        position="top-right" // Set the position of the notifications
        autoClose={3000} // Set the auto-close duration (3 seconds)
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        draggable
        progressClassName="toast-progress"
        zIndex={1000} // Set the desired z-index value
      /> */}
    </div>
  );
}

export default NewFooterComponent;
