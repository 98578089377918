import React from 'react';
import './NewsCard.css';

function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
}

function NewsCard(props) {
  const truncatedHeading = truncateText(props.heading, 45);
  const truncatedSubHeading = truncateText(props.subHeading, 50);

  return (
    <div className="news_card ">
      <div className="upper">
        <a href={`${props.a}`} alt='img'  target='_blank'  rel='noreferrer'>
          <img className="rounded-md" src={props.image} alt={props.alt} />
        </a>
      </div>
      <div className="lower">
        <a href={`${props.a}`}  className="type"  target='_blank'  rel='noreferrer'>
          News
        </a>
        <div className="date gap-4 min-h-[130px]">
          <ul>
            <li className="news_date">{props.date.d}</li>
            <li className="mb-6">{props.date.m}</li>
            <li className="mt-10">{props.place}</li>
          </ul>
          <ul className='relative'>
            <li>{truncatedHeading}</li>
            <li className="mb-11 mt-1 text-sm text-gray-500">{truncatedSubHeading}</li>
            <a className="read_more absolute bottom-0" href={`${props.a}`} target='_blank'  rel='noreferrer'>
              Read More...
            </a>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default NewsCard;
