import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../Components/CommonComponents/Navbar";
import SectionCard from "../Components/CommonComponents/SectionCard";
import NewFooterComponent from "../Components/CommonComponents/NewFooterComponent/NewFooterComponent";
import Seo from "../Components/CommonComponents/Seo";

function CancellationAndRefund() {
  const [test, setTest] = useState(false);
  const isNavbarOpened = () => {
    setTest((prev) => !test);
  };

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
      {/* Navbar Start  */}
      <Seo
        title={"Cancellation and Refund | Nextbigbox "}
        description={"Top It Solution in Delhi NCR."}
        name={"Nextbigbox"}
        type={"Article"}
      />
      <Navbar isNavbarOpened={isNavbarOpened} isHomePage={false} />
      {/* Navbar End  */}
      {/* Web Seo Main Component  */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <div className="px-[2rem] py-[5rem] md:px-[5.3rem] md:py-[8rem] privacy_policy cancellation_policy">
          <h1 className="text-[1.4rem] font-bold">
            Our Cancellation And Refund Policy{" "}
          </h1>
          <ul className="md:mt-2 md:w-[90%] text-justify">
            <ul>
              <li>CANCELLATION AND REFUND POLICY</li>
              <ul>
                <li>
                  This Cancellation and Refund Policy (“Policy”) is provided on
                  behalf of NEXT BIG BOX, a Tech Company, (herein referred to as
                  “Next Big Box,” “we,” “us,” or “our”). This Policy governs the
                  cancellation and refund process for payments made by clients
                  (herein referred to as “Clients” or “you”) to Next Big Box for
                  the purchase of goods or services. By engaging with Next Big
                  Box and making payments, you acknowledge and agree to comply
                  with this Policy.
                </li>
              </ul>
            </ul>
            <ul>
              <li>CANCELLATION OF ORDERS/SERVICES</li>
              <ul>
                <li>
                  1.1 : Client-Requested Cancellations: Clients may request to
                  cancel their orders or services by notifying Next Big Box
                  through the approved communication channels. Cancellation
                  requests must be made in writing and include the relevant
                  order or service details, including any unique identifiers,
                  such as order numbers or transaction IDs.
                </li>
                <li>
                  1.2 Next Big Box’s Right to Cancel: Next Big Box reserves the
                  right to cancel orders or services in certain situations,
                  including but not limited to:
                </li>
                <ol>
                  <li>
                    Insufficient inventory or resources to fulfill the order.
                  </li>
                  <li>
                    Non-compliance with Next Big Box’s terms and conditions.
                  </li>
                  <li>Suspicion of fraudulent or unauthorized activity.</li>
                  <li>Violation of applicable laws or regulations.</li>
                  <li>
                    Technological failures or errors that impact service
                    provision.
                  </li>
                </ol>
              </ul>
            </ul>
            <ul>
              <li>REFUNDS</li>
              <ul>
                <li>
                  2.1 Eligibility for Refunds: Refunds may be granted in the
                  following situations:
                </li>
                <li>
                  2.2 Refund Process: For eligible refund requests, Next Big Box
                  will process the refund within 5 days of approving the
                  request. Refunds will be made using the same payment method
                  used for the original transaction unless otherwise mutually
                  agreed upon by both parties.
                </li>
                <li>
                  2.3 Non-Refundable Items/Services: Certain items or services
                  may be designated as non-refundable. Next Big Box will clearly
                  communicate such instances to Clients prior to purchase.
                </li>
              </ul>
            </ul>
            <ul>
              <li>COMMUNICATION</li>
              <ul>
                <li>
                  3.1 Contact for Cancellation and Refund Requests: All
                  cancellation and refund requests should be directed to Next
                  Big Box’s designated customer support team. Contact details
                  can be found on the official Next Big Box website or provided
                  upon request.
                </li>
                <li>
                  3.2 Communication Channels: Next Big Box may accept
                  cancellation and refund requests through email, online
                  submission forms, or other designated communication channels.
                  Clients are encouraged to retain records of all communication
                  related to cancellation and refund requests.
                </li>
              </ul>
            </ul>
            <ul>
              <li>AMENDMENTS</li>
              <ul>
                <li>
                  4.1 Policy Updates: Next Big Box reserves the right to modify
                  or update this Policy at any time. Updated versions will be
                  posted on our official website, and the effective date will be
                  mentioned at the beginning of the Policy.
                </li>
              </ul>
            </ul>
            <ul>
              <li>GOVERNING LAW</li>
              <ul>
                <li>
                  5.1 Applicable Law: This Policy shall be governed by and
                  construed in accordance with the laws of the land, without
                  regard to its conflicts of law principles.
                </li>
                <li>
                  By proceeding with a purchase, you acknowledge that you have
                  read and understood this Cancellation and Refund Policy and
                  agree to be bound by its terms and conditions.
                </li>
                <li>
                  If you have any questions or concerns about this Policy,
                  please contact our customer support team at the details
                  mentioned on the website.
                </li>
                <li>
                  Note: The above policy is a general draft and may need to be
                  customized according to the specific requirements and laws of
                  Next Big Box’s jurisdiction. It is essential to seek legal
                  counsel to ensure the policy complies with all applicable laws
                  and regulations.
                </li>
              </ul>
            </ul>
          </ul>
        </div>
      </SectionCard>
      {/* Web Seo  Main Component End */}
      {/* FooterSection Start */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <NewFooterComponent />
      </SectionCard>
      {/* FooterSection End */}
    </div>
  );
}

export default CancellationAndRefund;
