import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../Components/CommonComponents/Navbar";
import SectionCard from "../Components/CommonComponents/SectionCard";
import EnterPriseSeoContent from "../Components/EnterPriseSeoContent/EnterPriseSeoContent";
import NewFooterComponent from "../Components/CommonComponents/NewFooterComponent/NewFooterComponent";
import Seo from "../Components/CommonComponents/Seo";

function EnterPriseSeo() {
  const [test, setTest] = useState(false);
  const isNavbarOpened = () => {
    setTest((prev) => !test);
  };

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
      <Seo
        name={"Nextbigbox"}
        type={"Article"}
        title={"Best Enterprise SEO Services and Companies | NextBigBox"}
        description={
          "Best Enterprise SEO Services which improve your Brand visibility by Nextbigbox. Our Seo Services boost awareness Join us for excellent Enterprise services."
        }
        canonical_url="https://www.nextbigbox.io/enterprise-seo-services"
        metaKeywords={[
          "enterprise seo services",
          "best enterprise seo services",
          "enterprise seo services in india",
          "enterprise seo",
          "seo services",
        ]}
      />

      {/* Navbar Start  */}
      <Navbar isNavbarOpened={isNavbarOpened} isHomePage={false} />
      {/* Navbar End  */}
      {/* E-commerce Seo Main Component  */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <EnterPriseSeoContent />
      </SectionCard>
      {/* E-commerce Seo  Main Component End */}
      {/* FooterSection Start */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <NewFooterComponent />
      </SectionCard>
      {/* FooterSection End */}
    </div>
  );
}

export default EnterPriseSeo;
