import React from 'react'
import "./PricingSectionContent.css";

function PricingSectionContent() {
  return (
    <div>
      <section className="pricing-section">
        <div className="container-fluid">
          <div className="webseo-container">
            <div className="row flex justify-center gap-5 webseo-cards">
              <div className="col-sm-4">
                <div className="card text-center">
                  <div className="title">
                    <i className="fa fa-paper-plane" aria-hidden="true"></i>
                    {/* h3 */}
                    <h3>Basic Plan</h3>
                  </div>
                  <div className="price">
                    <h4>
                      <sup>₹40,000</sup>
                    </h4>
                  </div>
                  <div className="option">
                    <ul>
                      <li>40 Keywords</li>
                      <li>60 Backlinks Per Month</li>
                      <li>Competitor Analysis</li>
                      <li>Duplicate Content Check</li>
                      <li>Google Penalty Check</li>
                      <li>Website Canonical Check</li>
                      <li>Title Tag Optimization</li>
                      <li>Meta Tag Optimization</li>
                      <li>Image Alt Tag Optimization</li>
                      <li>Content Optimization</li>
                      <li>SEO Friendly URL Setup</li>
                      <li>404 Page Implementation</li>
                      <li>Website Speed Check</li>
                      <li>Google Indexed Pages Check</li>
                      <li>Robots.txt Creation</li>
                      <li>Google XML Sitemap</li>
                      <li>Google Webmaster Setup</li>
                      <li>Google Analytics Setup</li>
                      <li>1 Blog Posting</li>
                      <li>1 Article Submission</li>
                      <li>10 Social Bookmarking</li>
                      <li>Profile Creation</li>
                      <li>3 Classified Submission</li>
                      <li>2 Image Sharing</li>
                      <li>GMB Creation One Time</li>
                    </ul>
                  </div>
                  <a
                    href="https://buy.stripe.com/cN28zVeuM1ZJeDm7sy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Order Now
                  </a>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="card text-center">
                  <div className="title">
                    <i className="fa fa-plane" aria-hidden="true"></i>

                    {/* h3 */}
                    <h3>Standard Plan</h3>
                  </div>
                  <div className="price">
                    <h4>
                      <sup>₹60,000</sup>
                    </h4>
                  </div>
                  <div className="option">
                    <ul>
                      <li>50 Keywords</li>
                      <li>90 Backlinks Per Month</li>
                      <li>Competitor Analysis</li>
                      <li>Duplicate Content Check</li>
                      <li>Google Penalty Check</li>
                      <li>Website Canonical Check</li>
                      <li>Title Tag Optimization</li>
                      <li>Meta Tag Optimization</li>
                      <li>Image Alt Tag Optimization</li>
                      <li>Content Optimization</li>
                      <li>SEO Friendly URL Setup</li>
                      <li>404 Page Implementation</li>
                      <li>Website Speed Check</li>
                      <li>Google Indexed Pages Check</li>
                      <li>Robots.txt Creation</li>
                      <li>Google XML Sitemap</li>
                      <li>Google Webmaster Setup</li>
                      <li>Google Analytics Setup</li>
                      <li>3 Blog Posting</li>
                      <li>3 Article Submission</li>
                      <li>15 Social Bookmarking</li>
                      <li>Profile Creation</li>
                      <li>5 Classified Submission</li>
                      <li>4 Image Sharing</li>
                      <li>GMB Creation One Time</li>
                    </ul>
                  </div>
                  <a
                    href="https://buy.stripe.com/5kA03pfyQ5bV1QAfZ3"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Order Now
                  </a>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="card text-center">
                  <div className="title">
                    <i className="fa fa-rocket" aria-hidden="true"></i>

                    {/* h3 */}
                    <h3>Premium Plan</h3>
                  </div>
                  <div className="price">
                    <h4>
                      <sup>₹80,000</sup>
                    </h4>
                  </div>
                  <div className="option">
                    <ul>
                      <li>60 Keywords</li>
                      <li>150 Backlinks Per Month</li>
                      <li>Competitor Analysis</li>
                      <li>Duplicate Content Check</li>
                      <li>Google Penalty Check</li>
                      <li>Website Canonical Check</li>
                      <li>Title Tag Optimization</li>
                      <li>Meta Tag Optimization</li>
                      <li>Image Alt Tag Optimization</li>
                      <li>Content Optimization</li>
                      <li>SEO Friendly URL Setup</li>
                      <li>404 Page Implementation</li>
                      <li>Website Speed Check</li>
                      <li>Google Indexed Pages Check</li>
                      <li>Robots.txt Creation</li>
                      <li>Google XML Sitemap</li>
                      <li>Google Webmaster Setup</li>
                      <li>Google Analytics Setup</li>
                      <li>5 Blog Posting</li>
                      <li>5 Article Submission</li>
                      <li>20 Social Bookmarking</li>
                      <li>Profile Creation</li>
                      <li>10 Classified Submission</li>
                      <li>6 Image Sharing</li>
                      <li>GMB Creation One Time</li>
                    </ul>
                  </div>
                  <a
                    href="https://buy.stripe.com/28oaI3euM6fZ66QdQU"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Order Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PricingSectionContent