import React from "react";
import person1 from "../../../media/person3.png";
import person2 from "../../../media/person1.png";
import person3 from "../../../media/person2.png";

function CtaSection2() {
  return (
    <div className=" pt-14 px-6 lg:pt-24 lg:px-32 overflow-hidden ">
      <div className="flex gap-6 lg:gap-12 border-t-[.2rem] border-t-[#ddd] border-b-0  ">
        <img src={person1} className="-mt-[3.1rem] h-[150px] " alt="person1" />
        <p
          className="text-[1rem] lg:text-[3.8rem] mt-6 lg:mt-0 font-montserrat font-semibold "
          style={{ letterSpacing: "-.1rem" }}
          data-aos="fade-right"
          data-aos-offset="50"
          data-aos-easing="ease-in-sine"
        >
          We can help you
        </p>
      </div>
      <div className="flex gap-6 lg:gap-48 flex-row-reverse border-t-[.2rem] border-t-[#ddd] border-b-0 ">
        <img
          src={person2}
          className="-mt-[3.8rem]  h-[158px] -z-1"
          alt="person1"
        />
        <p
          className="text-[1rem] lg:text-[3.8rem] mt-8 lg:mt-0 font-montserrat font-semibold z-0"
          style={{ letterSpacing: "-.1rem" }}
          data-aos="fade-left"
          data-aos-offset="50"
          data-aos-easing="ease-in-sine"
          data-aos-delay="200"
        >
          You to scale your
        </p>
      </div>

      <div className="flex gap-6 lg:gap-60 border-y-[.2rem] border-y-[#ddd]  ">
        <img src={person3} className="-mt-[3rem] h-[150px] " alt="person1" />
        <p
          className="text-[.9rem] lg:text-[3.8rem] mt-8 lg:mt-0 font-montserrat font-semibold "
          style={{ letterSpacing: "-.1rem" }}
          data-aos="fade-right"
          data-aos-offset="50"
          data-aos-easing="ease-in-sine"
          data-aos-delay="300"
        >
          Businesses!
        </p>
      </div>
      <div className="flex justify-end">
        <a href="/contact">
          <button className="m-3 flex p-2 px-4 mr-0 border-2 border-[#1b1b25] bg-[#1b1b25] text-white font-semibold text-2xl font-montserrat rounded-2xl">
            Get Started Now
          </button>
        </a>
      </div>
    </div>
  );
}

export default CtaSection2;
