import React from "react";
import "./ApiIntegrationContent.css";
import { Link } from "react-router-dom";
import CtaComponent from '../../Components/CommonComponents/CtaComponent/CtaComponent'
import ContactComponent from '../../Components/CommonComponents/ContactComponent/ContactComponent'
import blackArrow from "../../media/black-arrow.png";
import penny_drop from '../../media/penny.webp'
import rtgs_api from '../../media/rtgs.webp'
import neft_api from '../../media/neft.webp'
import imps_api from '../../media/imps.webp'
import upi_autopay from '../../media/upi_autopay.png'
import qr_code from '../../media/qr_code.png'
import whatsapp_icon from '../../media/whatsapp_icon.webp'
import mail from '../../media/email_icon.webp'
import message_icon from '../../media/sms_icon.webp'
import otp_icon from '../../media/opt_icon.webp'
import BannerBox from "../CommonComponents/BannerBox";
import pan_verification_logo from '../../media/pan_verificaiton_logo.webp'
import aadhar_verification_logo from '../../media/aadhar_verification_logo.webp'
import video_kyc_logo from '../../media/video_kyc_logo.webp'
import e_sign_logo from '../../media/e_sign_logo.webp'

function ApiIntegrationContent() {
  
  const DemoCtaText =
    "Together, we make your digital dreams a reality. Dive into the world of unmatched IT and digital marketing   solutions with us!";

  // window width
  let scrollValue = 0;
  const windowWidth = window.innerWidth;
  if (windowWidth > 500) {
    scrollValue = 3320;
  } else {
    scrollValue = 8250;
  }
  const ScrollDown = (scrollVal, scrollDuration) => {
    let windowWidth = window.innerWidth;
      if (windowWidth < 500) {
        let val = scrollVal - window.scrollY;
        setTimeout(() => {
          window.scrollBy({ top: val, letf: 0, behavior: "smooth" });
          // console.log('val',val);
        }, scrollDuration);
      } else {
        let val = scrollVal - window.scrollY;
        setTimeout(() => {
          window.scrollBy({ top: val, letf: 0, behavior: "smooth" });
          // console.log(val);
        }, scrollDuration);
      }
  };
  return (
    <div>
      <div className="main_api_page_content">
        <section className="api_hero_section api_section1">
          <div className="left">
            <h1>
              Enhance the efficiency of your <span> Business</span> with robust
              API integration services.
            </h1>
            <h2 className="font-light">
              <span> Nextbigbox </span>offers multiple API Integration services.
            </h2>
            <Link
              className="link-btn"
              onClick={() => {
                ScrollDown(scrollValue, 300);
              }}
            >
              Book A Personalized Demo{" "}
              <img src={blackArrow} className="black-arrow" alt="black-arrow" />
            </Link>
          </div>
          <div className="right " data-aos="fade-left">
            {/* <img src={api_gif} alt="img" /> */}
            <BannerBox />
          </div>
        </section>
        {/* <section></section> */}
        <section className="api_section_2">
          <h1>Our Banking API's</h1>
          <div className="api_card_container">
            <div className="api_card" data-aos="fade-up">
              <div className="left">
                <h2>Penny Drop</h2>
                <p>
                  With our penny drop you can easily demonstrate the legitimacy
                  of new customers by disbursing a minimum amount like Rs. 1 to
                  their bank account.
                </p>
              </div>
              <div className="right">
                <img src={penny_drop} alt="img" />
              </div>
            </div>
            <div className="api_card" data-aos="fade-up">
              <div className="left">
                <h2>IMPS API</h2>
                <p>
                  IMPS API will help you accomplish smooth interbank fund
                  transfers, payments, and mobile recharge, by making
                  transactions easy and ensuring all-time availabilities.
                </p>
              </div>
              <div className="right">
                <img src={imps_api} alt="img" />
              </div>
            </div>
            <div className="api_card" data-aos="fade-up">
              <div className="left">
                <h2>RTGS API</h2>
                <p>
                  RGet the freedom of transparent, secure and smooth fund
                  transfer with the interlinked bank account and enhance the
                  efficiency of financial connectivity and transfer.
                </p>
              </div>
              <div className="right">
                <img src={rtgs_api} alt="img" />
              </div>
            </div>
            <div className="api_card" data-aos="fade-up">
              <div className="left">
                <h2>NEFT API</h2>
                <p>
                  With the national electronic fund transfer your financial
                  transactions be streamlined with the API to perform in an
                  automated and efficient mode.
                </p>
              </div>
              <div className="right">
                <img src={neft_api} alt="img" />
              </div>
            </div>
          </div>
        </section>
        <section className="api_section3">
          <div className="left" data-aos="fade-left">
            <h1>Our Collective API's</h1>
            <p>
              <b>Dynamic</b> UPI Autopay and QR code payment collection enhance
              the service of automated collection where the updates as per the
              requirements can be implemented easily. It’s the best tool that
              can automate the payment and collection with an easy and efficient
              process.
            </p>
            <ul>
              <li>
                {" "}
                <b> Data : </b> Collection API streamlines data gathering.
              </li>
              <li>
                {" "}
                <b> Information : </b> Simplifies diverse information
                management.
              </li>
              <li>
                {" "}
                <b> Aggregation : </b> Offers efficient methods for data
                aggregation.
              </li>
            </ul>
          </div>
          <div className="right" data-aos="fade-right">
            <div className="sub_left">
              <img src={upi_autopay} alt="img" />
              <h3>UPI AUTOPAY</h3>
              <p>
                It helps to accomplish the payment with automatic mode,
                especially for the subscription and automated transaction.
              </p>
              <Link to="https://www.nextbigbox.io/blogs" target="blank">
                Read More
                <img
                  src={blackArrow}
                  className="black-arrow"
                  alt="black-arrow"
                />
              </Link>
            </div>
            <div className="sub_right">
              <img src={qr_code} alt="img" />
              <h3>Dynamic QR API Collection</h3>
              <p>
                It will help you easily generate the QR code for the collection
                of amounts with secure, transparent, and smooth solutions.
              </p>
              <Link to="https://www.nextbigbox.io/blogs" target="blank">
                Read More
                <img
                  src={blackArrow}
                  className="black-arrow"
                  alt="black-arrow"
                />
              </Link>
            </div>
          </div>
        </section>
        <section className="api_section4">
          <h1>General Purpose API's</h1>
          <div>
            <div className="card" data-aos="fade-up-right">
              <img src={whatsapp_icon} alt="img" />
              <h3>WhatsApp API</h3>
              <p>
                Our WhatsApp API will help you compose and share your message or
                information with existing and onboarding customers. It will
                enhance your communication in automated mode.
              </p>
              <Link to="https://www.nextbigbox.io/blogs" target="blank">
                Read More
                <img
                  src={blackArrow}
                  className="black-arrow"
                  alt="black-arrow"
                />
              </Link>
            </div>
            <div className="card" data-aos="fade-up">
              <img src={mail} alt="img" />
              <h3>Email API</h3>
              <p>
                Let’s plan, create and implement your message through the Email
                APIs that can help you spread your message and establish robust
                communication and connectivity with your clients.
              </p>
              <Link to="https://www.nextbigbox.io/blogs" target="blank">
                Read More
                <img
                  src={blackArrow}
                  className="black-arrow"
                  alt="black-arrow"
                />
              </Link>
            </div>
            <div className="card" data-aos="fade-up">
              <img src={message_icon} alt="img" />
              <h3>SMS API</h3>
              <p>
                Be there in the inbox of your target audience and existing
                clients with the information so that your message can impress
                your customers for the relevant proceeding. Let’s automate your
                business with us.
              </p>
              <Link to="https://www.nextbigbox.io/blogs" target="blank">
                Read More
                <img
                  src={blackArrow}
                  className="black-arrow"
                  alt="black-arrow"
                />
              </Link>
            </div>
            <div className="card" data-aos="fade-up-left">
              <img src={otp_icon} alt="img" />
              <h3>OTP API</h3>
              <p>
                Keenly verify your users and customers for the business with the
                OTP API that helps you get speed, authorization, and reliability
                with transparency in transaction and verification.
              </p>
              <Link to="https://www.nextbigbox.io/blogs" target="blank">
                Read More
                <img
                  src={blackArrow}
                  className="black-arrow"
                  alt="black-arrow"
                />
              </Link>
            </div>
          </div>
        </section>
      </div>
      <CtaComponent
        description={DemoCtaText}
        scrollVal={scrollValue}
        scrollDuration={100}
      />
      <div className="main_api_page_content main_api_page_content_next">
        <section className="api_section3 api_section3_r">
          <div className="left">
            <h1>Online KYC API's</h1>
            <p>
              Since online verification of customers is the key to the success
              of the financial solution and to perform these verification
              processes, you need to get trusted and effective online KYC APIs
              for the different functions. And we are providing all these for
              the betterment of your service.
            </p>
            <p>
              We have a collective and exclusive solution for the online KYC
              APIs that can help you accomplish different verification services
              like Aadhar, PAN, Video KYC, and E-signature therefore with our
              customized and state-of-the-art techniques we impart qualitative
              services that enhance your customer's trust and frequency and
              numbers.
            </p>
            <ul style={{ paddingTop: "2rem" }}>
              <li>
                {" "}
                <b> PAN Verification : </b> it helps you get the complete
                transactional details and information of your clients.
              </li>
              <li>
                {" "}
                <b> Aadhaar Verification : </b> Through the Aadhar verification
                get the identity and information of your customers.
              </li>
              <li>
                {" "}
                <b> Video KYC : </b> Enhance your customer’s identity process
                and verification easily through the Video KYC.
              </li>
              <li>
                {" "}
                <b> E-sign API : </b> Accomplish the digital signature and
                E-sign for the processing of financial transactions.
              </li>
            </ul>
          </div>
          <div className="right right_4">
            <div className="sub_left" data-aos="zoom-out-right">
              <img src={pan_verification_logo} alt="img" />
              <h3>Pan Verification</h3>
              <p>
                Get the complete details and verification with our PAN
                verification which adheres to all the guidelines and rules with
                the data security.
              </p>
              <Link to="https://www.nextbigbox.io/blogs" target="blank">
                Read More
                <img
                  src={blackArrow}
                  className="black-arrow"
                  alt="black-arrow"
                />
              </Link>
            </div>
            <div className="sub_right" data-aos="zoom-in-right">
              <img src={aadhar_verification_logo} alt="img" />
              <h3>Aadhaar Verification</h3>
              <p>
                Before proceeding with the financial process, it’s better to
                verify the Adhar and our APIs are there for your prior needs.
              </p>
              <Link to="https://www.nextbigbox.io/blogs/" target="blank">
                Read More
                <img
                  src={blackArrow}
                  className="black-arrow"
                  alt="black-arrow"
                />
              </Link>
            </div>
            <div className="sub_left" data-aos="zoom-out-right">
              <img src={video_kyc_logo} alt="img" />
              <h3>Video KYC (GPS)</h3>
              <p>
                Know your customers easily with face-to-face verification with
                our Video KYC service and minimize your workload.
              </p>
              <Link to="https://www.nextbigbox.io/blogs/" target="blank">
                Read More
                <img
                  src={blackArrow}
                  className="black-arrow"
                  alt="black-arrow"
                />
              </Link>
            </div>
            <div className="sub_right" data-aos="zoom-out-right">
              <img src={e_sign_logo} alt="img" />
              <h3>E-Sign API</h3>
              <p>
                Let's accomplish the process of digital signature with the
                E-sign API for your fintech service. Associate with us for
                better services.
              </p>
              <Link to="https://www.nextbigbox.io/blogs/" target="blank">
                Read More
                <img
                  src={blackArrow}
                  className="black-arrow"
                  alt="black-arrow"
                />
              </Link>
            </div>
          </div>
        </section>
      </div>
      <ContactComponent />
    </div>
  );
}

export default ApiIntegrationContent;
