import React from "react";
import "./CarrersContent.css";
import { Link } from "react-router-dom";
import carrer_hero from '../../media/carrer-hero-img.webp'
import carrer1 from '../../media/carrer-1.webp'
import carrer2 from '../../media/carrer-2.webp'
import carrer3 from '../../media/carrer-3.webp'
import carrer4 from '../../media/carrer-4.webp'
import carrer5 from '../../media/carrer-5.webp'
import carrer6 from '../../media/carrer-6.webp'
import SectionCard from "../CommonComponents/SectionCard";
import ContactComponent from "../CommonComponents/ContactComponent/ContactComponent";

function CarrersContent() {
    const openInNewTab = (url) => {
      const newWindow = window.open(url, "_blank");
      newWindow.opener = null; // For security
    };

  return (
    <div className="carrers_main">
      <section>
        <div className="op1s1">
          <h1 className="">Grow With Us</h1>
          <h2>
            We’ve joined Intuit! Together, we’re helping small and mid-market
            businesses grow with confidence. Check out our open roles or learn
            more about the acquisition. View Mailchimp Jobs
          </h2>
          <div>
            <button>
              <Link
                to={"https://www.linkedin.com/company/nextbigbox/jobs/"}
                target="blank"
              >
                View NextBigBox Jobs
              </Link>
            </button>
            <button>
              <Link to={"/about"}>About NextBigBox</Link>
            </button>
          </div>
        </div>
      </section>
      <section className="op1s2">
        <div className="left">
          <h1>Our Value</h1>
          <p>
            As an IT company, we work on a self-learning mechanism where we get
            ideas from all our experts. The values of every employee are
            prominent, and every insight imparts us to move positively and
            simultaneously.
          </p>
          <p>
            As a service agency, we strengthen our organization by sharing
            valuable ideas and implementing them for better results always. We
            follow some specific values like Innovative thinking, supportive
            behavior, appreciating for best, etc.
          </p>
          <p>
            We always try to move forward with the satisfaction of every client
            and our constituent parts.
          </p>
        </div>
        <div className="right">
          <img src={carrer_hero} alt="img" />
        </div>
      </section>
      <section className="op1s3">
        <h1>PERKS AT NEXTBIGBOX</h1>
        <ul>
          <li>
            <span>01/06</span>
            <h2>Time Off</h2>
            <p>
              We place a premium on work-life balance, fostering a culture where
              your well-being matters. With our comprehensive benefits, you'll
              enjoy generous paid time off and holidays. These provisions are
              designed to offer you ample opportunities to unwind, rejuvenate,
              and strike that essential harmony between your professional and
              personal life. We're dedicated to nurturing your overall
              happiness, allowing you to pursue your passions and spend quality
              time with loved ones while excelling in your career.
            </p>
          </li>
          <li>
            <img className="mt-4" src={carrer1} alt="img" />
          </li>
          <li>
            <span>02/06</span>
            <h2>Health & Wellness</h2>
            <p>
              Your well-being is our top priority. We've designed comprehensive
              health and wellness programs to address both your physical and
              mental needs. Our commitment is to provide the support and
              resources required for you to lead a balanced and fulfilling life.
              From fitness programs to mental health resources, we offer a
              variety of options to help you thrive. These initiatives ensure
              that you can maintain optimal well-being, both physically and
              mentally, allowing you to excel in all areas of life.
            </p>
          </li>
          <li>
            <img className="mt-12" src={carrer2} alt="img" />
          </li>
          <li>
            <span>03/06</span>
            <h2>Training</h2>
            <p>
              We cultivate a culture that champions continuous learning and
              personal growth. You'll enjoy unfettered access to a diverse array
              of training and development opportunities, equipping you with the
              tools to refine your skills and unlock your full potential. Our
              commitment is to empower you on your journey of professional and
              personal growth, fostering an environment where you can thrive and
              achieve your aspirations.
            </p>
          </li>
          <li>
            <img className="mt-8" src={carrer3} alt="img" />
          </li>
          <li>
            <span>04/06</span>
            <h2>Recognition</h2>
            <p>
              Our extraordinary team members hold a special place in our heart.
              We take pride in their exceptional contributions and celebrate
              their achievements through our robust recognition programs. These
              initiatives pay tribute to those who consistently go above and
              beyond, creating a culture of gratitude and solidarity within our
              organization. By acknowledging and appreciating our team's
              remarkable efforts, we foster an environment where each member
              feels valued and supported, driving excellence and camaraderie.
            </p>
          </li>
          <li>
            <img className="mt-8" src={carrer4} alt="img" />
          </li>
          <li>
            <span>05/06</span>
            <h2>Little Extras</h2>
            <p>
              We're dedicated to infusing each day with a sense of significance.
              Through tailored onboarding experiences, enjoyable office perks,
              and delightful surprises, we are committed to fashioning a
              workplace that is both positive and enriching. Our aim is to
              provide a rewarding environment for every member of our team,
              ensuring that each moment in the workplace is exceptional and
              filled with positivity, fostering a culture that values and
              uplifts all.
            </p>
          </li>
          <li>
            <img className="-mt-4" src={carrer5} alt="img" />
          </li>
          <li>
            <span>06/06</span>
            <h2>Innovative</h2>
            <p>
              Discover the key to unlocking your creativity through our
              innovative perks that provide the tools and support for shaping
              the future. Our commitment to fostering a culture of innovation
              creates an environment brimming with limitless possibilities. With
              these empowering resources, you're encouraged to think beyond
              boundaries, fostering a spirit of creativity and ingenuity that
              propels both you and our organization towards new horizons and
              uncharted potential.
            </p>
          </li>
          <li>
            <img className="mt-4" src={carrer6} alt="img" />
          </li>
        </ul>
      </section>
      {/* <section className="op1s4">
          <div className="left">
            
            <img className="mt-8" src={carrer_contact} alt="carrer-contact" />
          </div>
          <div className="main_contact_component_right">
            <div className="half">
              <label >Name</label>
              <input type="text" placeholder="Your Name" />
            </div>
            <div className="half">
              <label>Email</label>
              <input type="email" placeholder="Your Email" />
            </div>
            <div className="half">
              <label>Designation</label>
              <select id="project_type" name="project_type">
                <option value={""} disabled>
                  Which Role You Are Applying For :
                </option>
                <option value={"FrontEnd Developer"}>Frontend Developer</option>
                <option value={"BackEnd Developer"}> Backend Developer</option>
                <option value={"SEO Executive"}> SEO Executive</option>
                <option value={"Social Media Executive"}>
                  Social Media Executive
                </option>
                <option value={"Content Writer"}>Content Writer</option>
                <option value={"Google Adwords Specialist"}>
                  Google Adwords Specialist
                </option>
                <option value={"It Sales"}>It Sales</option>
              </select>
            </div>
            <div className="half">
              <label>Mobile</label>
              <input type="tel" placeholder="Your Mobile Number" />
            </div>
            <div className="half resume-upload">
              <label>Upload Resume</label>
              <input type="file" name="resume"/>
            </div>
            <div className="full">
              <label>Message</label>
              <textarea
                rows="2"
                cols="5"
                type="area"
                placeholder="Type Your Message..."
                style={{ width: "98%" }}
              />
            </div>
            <div className="full submit mt-6">
              <input type="button" value={"SUBMIT"} placeholder="SUMBIT" />
            </div>
          </div>
        </section> */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
      >
        <ContactComponent padding={true} />
      </SectionCard>
    </div>
  );
}

export default CarrersContent;
