import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../Components/CommonComponents/Navbar";
import SectionCard from "../Components/CommonComponents/SectionCard";
import EmailMarketingContent from "../Components/EmailMarketingContent/EmailMarketingContent";
import NewFooterComponent from "../Components/CommonComponents/NewFooterComponent/NewFooterComponent";
import Seo from "../Components/CommonComponents/Seo";

function EmailMarketing() {
  const [test, setTest] = useState(false);
  const isNavbarOpened = () => {
    setTest((prev) => !test);
  };

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
      <Seo
        name={"Nextbigbox"}
        type={"Article"}
        title={"Best Email Marketing Agency and Services | NextBigBox"}
        description={
          "NextBigBox is the Best choice for your email marketing needs. As the best email marketing agency, we specialize in delivering Best email marketing services."
        }
        canonical_url="https://www.nextbigbox.io/email-marketing-services"
        metaKeywords={[
          "Best Email Marketing Agency",
          "Email Marketing Services",
          "Email Campaigns",
          "Email Marketing Experts",
          "Customized Email Campaigns",
          "Email Campaign Management",
          "Email Segmentation",
          "Creative Email Design",
        ]}
      />
      {/* Navbar Start  */}
      <Navbar isNavbarOpened={isNavbarOpened} isHomePage={false} />
      {/* Navbar End  */}

      {/* Google Ads Main Component  */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <EmailMarketingContent />
      </SectionCard>
      {/* Google Ads Main Component End */}

      {/* FooterSection Start */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <NewFooterComponent />
      </SectionCard>
      {/* FooterSection End */}
    </div>
  );
}

export default EmailMarketing;
