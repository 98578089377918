import React, { useState } from "react";
import "./FaqSectionComponent.css";

function FaqSectionComponent({ faqHeading, faqData }) {
  const [openItems, setOpenItems] = useState(Array(faqData.length).fill(false));

  const toggleItem = (index) => {
    const newOpenItems = [...openItems];
    newOpenItems[index] = !newOpenItems[index];
    setOpenItems(newOpenItems);
  };

  return (
    <section id="faq" className="faq section-bg">
      <div className="containerf" data-aos="fade-up">
        <div className="section-title ">
          <h3>Frequently Asked Questions</h3>
          <p>{faqHeading}</p>
        </div>

        <div className="faq-list">
          <ul>
            {faqData.map((item, index) => (
              <li data-aos="fade-up" key={index + 1}>
                <i className="bx bx-help-circle icon-help"></i>{" "}
                <p
                  onClick={() => toggleItem(index)}
                  className={openItems[index] ? "collapsed" : ""}
                >
                <span style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                  <span style={{width: '100%'}}>Q{index + 1}. {item.q}{" "}</span>
                  <span style={{width: '2rem'}} id="faq_btn"> {openItems[index] ? "-" : "+"}</span>
                </span> 
                </p>
                <div
                  className={`faq-answer ${openItems[index] ? "open" : ""}`}
                  id="faqAnswer"
                >
                  <p id="faqAnswer">A{index + 1}. {item.a}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default FaqSectionComponent;
