import React from "react";
import "./LinkedinMarketingContent.css";
import CtaComponent from "../CommonComponents/CtaComponent/CtaComponent";
import linkedin_marketing from "../../media/LinkedIn-Marketing.png";
import reachTargetAudience from "../../media/Target-Audience.webp";
import thoughtLeadership from "../../media/Leadership.webp";
import qualityLeads from "../../media/Drive-Qualified-Leads.webp";
import contentStrategy from "../../media/Content-Strategy.webp";
import ARROW from "../../media/arrow.svg";
import whyChooseOurLs from "../../media/Why-Choose-NextBigBox.webp";
import howMarketingAnalysis from "../../media/How-Marketing-Analysis.webp";
import ContactComponent from "../CommonComponents/ContactComponent/ContactComponent";
import SectionCard from "../CommonComponents/SectionCard";
import FaqSectionComponent from "../CommonComponents/FaqSectionComponent/FaqSectionComponent";

function LinkedInMarketingContent() {
  const faqData = [
    // Define your FAQ data here with questions and answers
    {
      q: "What is the range of LinkedIn Marketing?",
      a: "It can be used for the ad campaign, for the B2B and B2C marketing so that you can have a broader area of customers.",
    },
    {
      q: "How can it be beneficial for my business?",
      a: "It helps you ensure a maximum number of clients and customers so that you can have a higher number of clients.  ",
    },
    {
      q: "What are the specific features of LinkedIn Marketing services?",
      a: "It has enormous features that help you get expertise and experience customize strategies, targeted reach, content excellence, data-driven insight, and cost-effective results.",
    },
    {
      q: "What are the benefits of choosing a professional LinkedIn Marketing Services? ",
      a: "It helps you get better analytical ideas with the professional LinkedIn marketing where you can get the data with the categories and all.",
    },
  ];

  const DemoCtaText =
    "Elevate your brand's presence in the professional world. Discover the power of LinkedIn Marketing with NextBigBox and achieve your business objectives.";

  const faqHeading =
    "You might have enormous questions about LinkedIn Marketing we are there until your last question is answered. ";

  // window width
  let scrollValue = 0;
  const windowWidth = window.innerWidth;
  if (windowWidth > 500) {
    scrollValue = 3420;
  } else {
    scrollValue = 6700;
  }

  return (
    <>
      <div className="services_content_page linkedin-sp">
        <section className="sp1s1" data-aos="fade-up">
          <div className="left">
            <h1>Growth your Business with LinkedIn Marketing Services</h1>
            <p>
              Get enormous benefits from LinkedIn Marketing services which is
              one of the best ways to boost your business with a wider range of
              professionals, B2B services, analytics and ideas of the multiple
              factors that are invariably related to business growth. Here with
              LinkedIn marketing services, we focus on all the components like
              content creation, strategies, captivating templates, ad campaigns,
              and all and it will certainly enhance your business growth in a
              limited period.
            </p>
            <p>
              Considering multiple features and benefits including Expertise and
              Experience, Customize Strategies, Targeted Reach, Content
              Excellence, Data-Driven Insight, Cost-Effective Results, and we
              implement all these strategies for analytic and better lead
              generation so that you can get a higher density of customers and
              clients. While setting up the campaign for LinkedIn Marketing we
              perform for both i.e., B2B and B2C with the perfection in data and
              lead generation.
            </p>
          </div>
          <div className="right">
            <img src={linkedin_marketing} alt="img" />
          </div>
        </section>
        <section className="sp5s2">
          <div className="about_page_our_story">
            <h2
              className="font-oswald font-bold text-center text-[1.8rem]"
              data-aos="fade-up"
            >
              Our LinkedIn Marketing Processes
            </h2>
            <div>
              <div
                className="our_story_item border our_story_item1 h-[450px]"
                data-aos="zoom-out-right"
              >
                <img
                  src={reachTargetAudience}
                  alt="2020"
                  className=""
                  draggable="false"
                />
                <b>Target Audience </b>
                <p>
                  We target specific customer groups to expand reach and boost
                  business growth across a wider network.
                </p>
              </div>
              <img
                src={ARROW}
                alt="arrow"
                className="w-10 h-10 double-arrow"
                draggable="false"
              />
              <div
                className="our_story_item h-[450px]"
                data-aos="zoom-out-left"
              >
                <img
                  src={contentStrategy}
                  alt="2023"
                  className=""
                  draggable="false"
                />
                <b>Content Strategy</b>
                <p>
                  A structured plan outlining the creation, distribution, and
                  management of content to engage and provide value to a defined
                  audience.
                </p>
              </div>
              <img
                src={ARROW}
                alt="arrow"
                className="w-10 h-10 double-arrow"
                draggable="false"
              />
              <div className="our_story_item h-[450px]" data-aos="zoom-out-up">
                <img
                  src={qualityLeads}
                  alt="2022"
                  className=""
                  draggable="false"
                />
                <b>Drive Qualified Leads </b>
                <p>
                  With the ensured quality services, we always try to get
                  genuine and interested customers for better interaction and
                  results.
                </p>
              </div>
              <img
                src={ARROW}
                alt="arrow"
                className="w-10 h-10 double-arrow"
                draggable="false"
              />
              <div className="our_story_item h-[450px]" data-aos="zoom-out-up">
                <img
                  src={thoughtLeadership}
                  alt="2021"
                  className=""
                  draggable="false"
                />
                <b>Leadership</b>
                <p>
                  With inspirational and motivational ideas, we set the campaign
                  to guide and motivate for the desired achievements and all.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <CtaComponent
        description={DemoCtaText}
        scrollVal={scrollValue}
        scrollDuration={100}
      />
      <div className="services_content_page services_content_page_middle linkedin-sp">
        <section className="sp6s2">
          <div className="left">
            <h2 className="font-bold text-[1.5rem]">
              Why Choose NextBigBox Over Others
            </h2>
            <ul>
              <li>
                <b>Expertise and Experience :</b> We have a team of experts
                having multiple years of marketing experience and they are keen
                to set the ad campaign for better results and quality leads.
              </li>
              <li>
                <b>Customized Strategies :</b> We use customised strategies
                i.e., considering all the requirements with location,
                categories, age group and all for the perfect customer
                selection.
              </li>
              <li>
                <b>Targeted Reach :</b>We always focus on the targeted reach by
                analysing the customers so that the marketing strategies can be
                set and implemented with perfection and better results.
              </li>
              <li>
                <b>Content Excellence :</b> We always emphasise high-quality
                content in all areas like designing, captivating and relevant
                content ad creatives and, we focus content on a priority basis.
              </li>
              <li>
                <b>Data-Driven Insights :</b> With the perfection and
                credibility in Data, we always plan and implement a Data-Driven
                Insight so that we can classify the required data with
                transparency.
              </li>
              <li>
                <b>Cost-Effective Results :</b> We always focus on efficiency
                and outcomes with higher revenue which is the key to the success
                of our LinkedIn marketing campaign and therefore we perform
                efficiently.
              </li>
            </ul>
          </div>
          <div className="right mt-[7rem]">
            <img src={whyChooseOurLs} alt="img" />
          </div>
        </section>
        <section className="sp6s3">
          <div className="left">
            <img src={howMarketingAnalysis} alt="img" />
          </div>
          <div className="right">
            <h2 className="font-bold text-[1.5rem]">
              Features of a LinkedIn marketing analysis campaign
            </h2>
            <ul>
              <li>
                <b>Competitors Analysis :</b> It helps to get insight into your
                competitor's activities that help you get enormous ideas and
                strategies for the betterment of the campaign.
              </li>
              <li>
                <b>B2B Professionals :</b> There are lots of B2B professionals
                on <b> LinkedIn Marketing </b> and therefore it helps you
                enhance your credibility and revenue growth.
              </li>
              <li>
                <b>Insight of Location :</b> It helps you get the perfect ideas
                of location that can be beneficial for selecting the scope of
                the business and all.
              </li>
              <li>
                <b>Age Group Analysis :</b> Setting the age group analysis helps
                to identify the actual users and customers, and simultaneously
                enhance the business reach.
              </li>
              <li>
                <b>Analysis with Designation :</b> You can get the analytical
                report with the designation and profile of the visitors which is
                more effective for the data analysis.
              </li>
              <li>
                <b>Complete Analysis with Brief Details :</b> Overall, you will
                be able to get better insight and ideas of the complete analysis
                so that you can get perfection in the analytical report and all.
              </li>
            </ul>
          </div>
        </section>
        <section className="sp1s4 mt-24">
          <FaqSectionComponent faqHeading={faqHeading} faqData={faqData} />
        </section>
      </div>
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
      >
        <ContactComponent />
      </SectionCard>
    </>
  );
}

export default LinkedInMarketingContent;
