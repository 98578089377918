import React from "react";

const PageCard = ({ title, content }) => {
  return (
    <div
      className="card w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-6 border-2 rounded"
      data-aos="zoom-in"
    >
      <h3 className="font-bold text-center text-xl underline">{title}</h3>
      <p className="text-justify mt-4">{content}</p>
    </div>
  );
};

export default PageCard;
