import React from "react";
import "./GShoppingAdsContent.css";
import { Link } from "react-router-dom";
import GoogleShoppingAdsHero from "../../media/google-shopping-ads-hero.webp";
import gsat from '../../media/Types-Of-Google.webp'
import blue_arrow from "../../media/blue_arrow.jpg";
import reviewIcon from '../../media/Free-suitability-review.webp'
import DiscoveryAndAnalysis from '../../media/Discovery-&-Analysis-phases.webp'
import accountSetup from '../../media/Account-set-up.webp'
import optimizationIcon from '../../media/On-going-optimisations.webp'
import reportingIcon from '../../media/Reporting-&-Consultation.webp'
import CtaComponent from "../CommonComponents/CtaComponent/CtaComponent";
import FaqSectionComponent from "../CommonComponents/FaqSectionComponent/FaqSectionComponent";
import ContactComponent from "../CommonComponents/ContactComponent/ContactComponent";
import SectionCard from "../CommonComponents/SectionCard";

function GShoppingAdsContent() {

  const faqData = [
    {
      q: "What is Google Shopping Ads Service?",
      a: "It’s a marketing strategy for your online business so that your products can be found easily on the Google search engine. ",
    },
    {
      q: "How can it enhance my business revenue?",
      a: "Once your products get more customers and engagement, your business value becomes higher, and it will certainly enhance your business revenue",
    },
    {
      q: " What are the specific features of Google Ads service?",
      a: "We always care about the delivery on the committed time, however, the actual time required for the full stake development will take some time.",
    },
    {
      q: "What is the budget for the web development services? ",
      a: "It Can be used for product listing, area-wise services and all. ",
    },
    {
      q: "What will be the cost to start this service?",
      a: "As per your package and business plan it will depend, and you can get the brief ideas. However, we have an affordable plan. ",
    },
  ];
  const faqHeading =
    "Let’s resolve all the queries that might be emerging in your mind, especially for the web development services. And we are here to answer until your last question is resolved.  ";
  const DemoCtaText =
    "Elevate your products and brand’s presence in the digital world. Get associated with the potential of Google Shopping Ads with NextBigBox and accomplish your business objectives.";

  return (
    <>
      <div className="google_service_page">
        <section className="sp6s1">
          <section className="salescrm_page_content_hero">
            <div className="upper">
              <h1 data-aos="zoom-in">
                How does our Google Shopping campaign work
              </h1>
              <p>
                Manage everything either multiple products or sales at a single
                platform.
              </p>
            </div>
            <div className="bottom">
              <div className="left" data-aos="zoom-in-up">
                <img src={GoogleShoppingAdsHero} alt="img" />
              </div>
              <div className="right" data-aos="zoom-in-down">
                <h1>Google Shopping Ads</h1>
                <p>
                  It always lets your products or the advertising or E-Commerce
                  at the top of the Google Search Engine.
                </p>
                <ul className="list-item">
                  <li>
                    <svg
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      className="bi bi-plus-circle"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />{" "}
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />{" "}
                    </svg>
                    <span>Data Feed Creation for the Products</span>
                  </li>
                  <li>
                    <svg
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      className="bi bi-plus-circle"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />{" "}
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />{" "}
                    </svg>
                    <span>Google Merchant Center Setup</span>
                  </li>
                  <li>
                    <svg
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      className="bi bi-plus-circle"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />{" "}
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />{" "}
                    </svg>
                    <span>Multi-channel auto responders Setup </span>
                  </li>
                  <li>
                    <svg
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      className="bi bi-plus-circle"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />{" "}
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />{" "}
                    </svg>
                    <span>Campaign Configuration</span>
                  </li>
                  <li>
                    <svg
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      className="bi bi-plus-circle"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />{" "}
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />{" "}
                    </svg>
                    <span>Product Grouping and Segmentation</span>
                  </li>
                  <li>
                    <svg
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      className="bi bi-plus-circle"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />{" "}
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />{" "}
                    </svg>
                    <span>Ad Optimization and Creation</span>
                  </li>
                </ul>
                <Link
                  className="reading_page_link"
                  to={
                    "https://www.nextbigbox.io/how-digital-marketing-is-helping-e-commerce-to-grow-2/"
                  }
                  target="blank"
                >
                  Explore How Google Shopping Ads Works?
                  <img src={blue_arrow} alt="img" />
                </Link>
              </div>
            </div>
          </section>
          <section className="role_based_authentication sp6s1">
            <h1>Multiple Types of Google Shopping Ads</h1>
            <div>
              <div className="left" data-aos="zoom-in-down">
                <ul>
                  <li>
                    <span>
                      <svg
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        className="bi bi-shield-lock -rotate-90"
                        viewBox="0 0 16 16"
                      >
                        {" "}
                        <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />{" "}
                        <path d="M9.5 6.5a1.5 1.5 0 0 1-1 1.415l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99a1.5 1.5 0 1 1 2-1.415z" />{" "}
                      </svg>
                      <p className="role_head">Product Listing</p>
                    </span>
                    <p>
                      It’s used to visualize the ads where the images of
                      products are added to provide information.
                    </p>
                  </li>
                  <li>
                    <span>
                      <svg
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        className="bi bi-shield-lock -rotate-90"
                        viewBox="0 0 16 16"
                      >
                        {" "}
                        <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />{" "}
                        <path d="M9.5 6.5a1.5 1.5 0 0 1-1 1.415l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99a1.5 1.5 0 1 1 2-1.415z" />{" "}
                      </svg>
                      <p className="role_head">Local Inventory Ads</p>
                    </span>
                    <p>
                      It’s used to optimise the Ads and product list to enhance
                      the visibility of stores in local areas.
                    </p>
                  </li>
                  <li>
                    <span>
                      <svg
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        className="bi bi-shield-lock -rotate-90"
                        viewBox="0 0 16 16"
                      >
                        {" "}
                        <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />{" "}
                        <path d="M9.5 6.5a1.5 1.5 0 0 1-1 1.415l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99a1.5 1.5 0 1 1 2-1.415z" />{" "}
                      </svg>
                      <p className="role_head">Dynamic Remarking Ads</p>
                    </span>
                    <p>
                      To enhance the visibility of your products, especially for
                      past visitors so they can appear easily.
                    </p>
                  </li>
                  <li>
                    <span>
                      <svg
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        className="bi bi-shield-lock -rotate-90"
                        viewBox="0 0 16 16"
                      >
                        {" "}
                        <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />{" "}
                        <path d="M9.5 6.5a1.5 1.5 0 0 1-1 1.415l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99a1.5 1.5 0 1 1 2-1.415z" />{" "}
                      </svg>
                      <p className="role_head">Showcase Shopping Ads</p>
                    </span>
                    <p>
                      Showcase shopping ads are used to widespread the product
                      ads and awareness to get potential customers.
                    </p>
                  </li>
                </ul>
              </div>
              <img
                className="right"
                src={gsat}
                alt="img"
                data-aos="zoom-in-up"
              />
            </div>
          </section>
          <section className="portfolio_support_section google_shopping_ads">
            <h2 className="support_we_provide support_we_provide_up ">
              We are there for you at every step
            </h2>
            <div>
              <div className="support_card support_we_provide_card_rotate">
                <img src={reviewIcon} alt="img" draggable="false" />
                <p>Free suitability review</p>
              </div>
              <div className="support_card support_we_provide_card_rotate">
                <img src={DiscoveryAndAnalysis} alt="img" draggable="false" />
                <p>Discovery & Analysis phases</p>
              </div>
              <div className="support_card support_we_provide_card_rotate">
                <img src={accountSetup} alt="img" draggable="false" />
                <p>Account set up</p>
              </div>
              <div className="support_card support_we_provide_card_rotate">
                <img src={optimizationIcon} alt="img" draggable="false" />
                <p>On-going optimisations</p>
              </div>
              <div className="support_card support_we_provide_card_rotate">
                <img src={reportingIcon} alt="img" draggable="false" />
                <p>Reporting & Consultation</p>
              </div>
            </div>
          </section>
          <section className="sp1s4">
            {/* <div className="faq-container">
              <h2>Frequently Asked Questions</h2>
              {faqData.map((item, index) => (
                <div key={index} className="faq-item " data-aos="fade-up">
                  <button
                    className={`accordion flex justify-between ${
                      activeAccordion === index ? "active" : " "
                    }`}
                    onClick={() => toggleAccordion(index)}
                  >
                    <span>{item.question}</span>
                    <span>
                      {activeAccordion === index ? (
                        <span className="float-right">&#8595;</span>
                      ) : (
                        <span> &uarr; </span>
                      )}
                    </span>
                  </button>
                  <div
                    className={`panel ${
                      activeAccordion === index ? "active visible" : ""
                    }`}
                  >
                    {item.answer}
                  </div>
                </div>
              ))}
            </div> */}

            <FaqSectionComponent faqHeading={faqHeading} faqData={faqData} />
          </section>
        </section>
      </div>
      <CtaComponent description={DemoCtaText} />
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
      >
        <ContactComponent />
      </SectionCard>
    </>
  );
}

export default GShoppingAdsContent;
