import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../Components/CommonComponents/Navbar";
import SectionCard from "../Components/CommonComponents/SectionCard";
import GoogleAdsPageContent from "../Components/GoogleAdsPageContent/GoogleAdsPageContent";
import NewFooterComponent from "../Components/CommonComponents/NewFooterComponent/NewFooterComponent";
import Seo from "../Components/CommonComponents/Seo";

function GoogleAds() {
  const [test, setTest] = useState(false);
  const isNavbarOpened = () => {
    setTest((prev) => !test);
  };

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
      <Seo
        name={"Nextbigbox"}
        type={"Article"}
        title={"Best Google Ads Agency in India | NextBigbox"}
        description={
          "NextBigBox experts listed the top Google Ads agencies that help Your businesses create and Get More Lead with Google Ads Services. We offer google ad management."
        }
        canonical_url="https://www.nextbigbox.io/google-ads-services"
        metaKeywords={[
          "google adwords marketing services",
          "google ads agency",
          "google ads services",
          "google ads service provider",
          "best google ads agency in india",
        ]}
      />

      {/* Navbar Start  */}
      <Navbar isNavbarOpened={isNavbarOpened} isHomePage={false} />
      {/* Navbar End  */}

      {/* Google Ads Main Component  */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <GoogleAdsPageContent />
      </SectionCard>
      {/* Google Ads Main Component End */}

      {/* FooterSection Start */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <NewFooterComponent />
      </SectionCard>
      {/* FooterSection End */}
    </div>
  );
}

export default GoogleAds;
