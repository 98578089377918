import React, { Children, useEffect } from "react";
import "./App.css";
import {
  createBrowserRouter,
  RouterProvider,
  // Route,
  // Link,
} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import ComingSoonPage from "./pages/ComingSoonPage";
import DynamicServiceContent from "./pages/DynamicServiceContent";
import Home from "./pages/Home";
import About from "./pages/About";
import Portfolio from "./pages/Portfolio";
import Contact from "./pages/Contact";
import WebDevelopment from "./pages/WebDevelopment";
import Crm from "./pages/Crm";
import SalesCrm from "./pages/SalesCrm";
import LoanManagement from "./pages/LoanManagement";
import Hrms from "./pages/Hrms";
import GoogleAds from "./pages/GoogleAds";
import NewsAndEvents from "./pages/NewsAndEvents";
import ApiIntegration from "./pages/ApiIntegration";
import FintechServices from "./pages/FintechServices";
import PPC from "./pages/PPC";
import EmailMarketing from "./pages/EmailMarketing";
import SocialMediaMarketing from "./pages/SocialMediaMarketing";
import LinkedinMarketing from "./pages/LinkedinMarketing";
import GShoppingAds from "./pages/GShoppingAds";
import WebSeo from "./pages/WebSeo";
import LocalSeo from "./pages/LocalSeo";
import EcommerceSeo from "./pages/EcommerceSeo";
import EnterPriseSeo from "./pages/EnterPriseSeo";
import Carrers from "./pages/Carrers";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CancellationAndRefund from "./pages/CancellationAndRefund";
import OurTeam from "./pages/OurTeam";
import Smo from "./pages/Smo";
import { HelmetProvider } from "react-helmet-async";
import Blogs from "./pages/Blogs";
import BlogsFullPageContent from "./Components/CommonComponents/BlogsPageContent/BlogFullPageContent";
import FullBlogPage from "./pages/FullBlogPage";
import NewPortfolio from "./pages/NewPortfolio";
import SeoPage from "./Components/DynamicSeoPages/SeoPage";
import DynamicSeo from "./pages/DynamicSeo";
import locations from "./data/locations.json";
import NotFoundPage from "./Components/CommonComponents/NotFoundPage";
import DynamicSeoPageContent from "./Components/DynamicSeoPages/DynamicSeoPageContent";
import DigitalMarketingPage from "./pages/DigitalMarketingPage";
import DynamicCategory from "./pages/DynamicCategory";
import countryLocation from "./data/countryLocation.json";
import DynamicLocation from "./pages/DynamicLocation";
import DigitalMarketingAgency from "./pages/DigitalMarketingAgency";
import LandingPage from "./pages/EmailMarketingLandingPage";
import EmailMarketingLandingPage from "./pages/EmailMarketingLandingPage";
import { ToastContainer } from "react-toastify";
const loadLocationData = ({ params }) => {
  const location = locations.find((loc) => loc.slug === params.id);
  if (!location) {
    throw new Response("Not Found", { status: 404 });
  }
  return location;
};

const router = createBrowserRouter([
  // main pages
  {
    path: "/",
    element: <Home />,
    errorElement: <NotFoundPage />,
  },

  // Dynamic Routes for Location Page
  // ...countryLocation.map((location) => ({
  //   path: `/${location.country}`,
  //   element: <DynamicCategory />,
  //   children: [
  //     {
  //       path: "example",
  //       element: <DynamicLocation />,
  //     },
  //   ],
  // })),

  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/portfolio",
    element: <Portfolio />,
  },
  // {
  //   path: "/dynamiclocation",
  //   element : (<DynamicLocation/>),

  // },
  {
    path: "/case-studies",
    element: <NewPortfolio />,
  },
  // {
  //   path: "/blogs/:id",
  //   errorElement: <NotFoundPage />,
  // },
  // solution pages
  {
    path: "/web-development",
    element: <WebDevelopment />,
  },
  {
    path: "/digital-marketing-agency",
    element: <DigitalMarketingAgency />,
  },
  {
    path: "/fintech-crm",
    element: <Crm />,
  },
  {
    path: "/sales-crm",
    element: <SalesCrm />,
  },
  {
    path: "/loan-crm",
    element: <LoanManagement />,
  },
  {
    path: "/hrms",
    element: <Hrms />,
  },
  {
    path: "/api-integration",
    element: <ApiIntegration />,
  },
  {
    path: "/fintech-solution",
    element: <FintechServices />,
  },

  // services pages
  {
    path: "/google-ads-services",
    element: <GoogleAds />,
  },
  {
    path: "/ppc-ads-services",
    element: <PPC />,
  },
  {
    path: "/email-marketing-services",
    element: <EmailMarketing />,
  },
  {
    path: "/digital-marketing",
    element: <DigitalMarketingPage />,
  },
  {
    path: "/social-media-marketing-services",
    element: <SocialMediaMarketing />,
  },
  {
    path: "/linkedin-marketing-services",
    element: <LinkedinMarketing />,
  },
  {
    path: "/google-shopping-ads",
    element: <GShoppingAds />,
  },
  {
    path: "/search-engine-optimization",
    element: <WebSeo />,
  },
  {
    path: "/smo",
    element: <Smo />,
  },
  {
    path: "/local-seo-services",
    element: <LocalSeo />,
  },
  {
    path: "/ecommerce-seo-services",
    element: <EcommerceSeo />,
  },
  {
    path: "/enterprise-seo-services",
    element: <EnterPriseSeo />,
  },

  // Dynamics Seo Pages
  // {
  //   path: "/:title",
  //   element: <DynamicSeo />,
  // },
  // other pages
  // {
  //   path: "/blogs",
  //   element: <Blogs />,
  // },
  // {
  //   path: "/blogs/:id",
  //   element: <FullBlogPage />,
  // },
  {
    path: "/coming_soon",
    element: <ComingSoonPage />,
  },
  {
    path: "/news-and-events",
    element: <NewsAndEvents />,
  },
  {
    path: "/careers",
    element: <Carrers />,
  },
  // {
  //   path: "/teams",
  //   element: <OurTeam />,
  // },
  {
    path: "/terms-and-conditions",
    element: <TermsAndConditions />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/cancellation-and-refund-policy",
    element: <CancellationAndRefund />,
  },

  {
    path: "/email-marketing",
    element : <EmailMarketingLandingPage/>
  }

  // {
  //   path : "/country",
  //   element : <DynamicCountry/>
  // },

  // {
  //   path : "/servicecontent",
  //   element : <DynamicServiceContent/>
  // }
]);

const helmetContext = {};

function App() {
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HelmetProvider context={helmetContext}>
        <RouterProvider router={router} />
      </HelmetProvider>
      <ToastContainer
        position="top-right" // Set the position of the notifications
        autoClose={3000} // Set the auto-close duration (3 seconds)
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        draggable
        progressClassName="toast-progress"
        zIndex={1000} // Set the desired z-index value
      />
    </>
  );
}

export default App;
