import React, { useState } from "react";
import './ImageGallery.css'

function ImageGallery(props) {
    const dir = props.dir;
    const images = props.images;

  const [currentImage1, setCurrentImage1] = useState(images[0]);
  const [currentImage2, setCurrentImage2] = useState(images[0]);

  const activate1 = (e) => {
    if (e.target.tagName !== "IMG") return;
    setCurrentImage1(e.target.src);
  };
  const activate2 = (e) => {
    if (e.target.tagName !== "IMG") return;
    setCurrentImage2(e.target.src);
  };

  return (
    <>
    {
        dir === 'ltr' ? (
            <div className="gallery">
            <div className="gal_1">
            {images.map((image, index) => (
                <img key={index} src={image} alt={`${index}`} onClick={activate1} />
            ))}
            </div>
            <img className="hero" src={currentImage1} alt="Hero" onClick={activate1} />
            </div>
        ) : (
            <div className="gallery">
            <img className="hero" src={currentImage2} alt="Hero" onClick={activate2} />
            {images.map((image, index) => (
                <img key={index} src={image} alt={`${index}`} onClick={activate2} />
            ))}
            </div>
        )
    }
    </>
  );
}

export default ImageGallery;
