import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../Components/CommonComponents/Navbar";
import SectionCard from "../Components/CommonComponents/SectionCard";
import SocialMediaMarketingContent from "../Components/SocailMediaMarketingContent/SocialMediaMarketingContent";
import NewFooterComponent from "../Components/CommonComponents/NewFooterComponent/NewFooterComponent";
import Seo from "../Components/CommonComponents/Seo";

function EmailMarketing() {
  const [test, setTest] = useState(false);
  const isNavbarOpened = () => {
    setTest((prev) => !test);
  };

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
      <Seo
        name={"Nextbigbox"}
        type={"Article"}
        title={"Search Media Marketing Services | Best SMM Services Agency"}
        description={
          "Best Social Media Marketing Services to increase your online presence with the Best SMM services Agency for effective social media strategies and campaigns."
        }
        canonical_url="https://www.nextbigbox.io/social-media-marketing-services"
        metaKeywords={[
          "Search Media Marketing Services",
          "Best SMM Services Agency",
          "social media marketing",
          "social media strategies",
          "SMM services",
          "marketing campaigns",
          "digital marketing agency",
        ]}
      />

      {/* Navbar Start  */}
      <Navbar isNavbarOpened={isNavbarOpened} isHomePage={false} />
      {/* Navbar End  */}

      {/* Google Ads Main Component  */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <SocialMediaMarketingContent />
      </SectionCard>
      {/* Google Ads Main Component End */}

      {/* FooterSection Start */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <NewFooterComponent />
      </SectionCard>
      {/* FooterSection End */}
    </div>
  );
}

export default EmailMarketing;
