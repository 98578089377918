import React from "react";

function EmailPageCardComponent({ data, formHeading , altText}) {
  // hover:border-[#057aff] hover:rounded-xl
  return (
    <div className="w-4/5 sm:w-1/2 md:w-1/3 lg:w-1/4 flex flex-col h-auto border-2 rounded-lg  items-center justify-start gap-4 transition-all transition-400 overflow-hidden hover:border-[#057aff] hover:rounded-xl">
      <div className=" w-full h-1/2 flex flex-row justify-center items-center">
        <img src={data.image} alt={altText} height={'100px'} className="w-full max-h-[350px]"/>
      </div>
      <div className="flex flex-col w-full h-full p-4 justify-between items-center ">
        <div>
          <h3 className=" text-center font-bold text-xl mb-4">{data.title}</h3>
          <p className="text-start mb-4">{data.description}</p>
        </div>
        <button
          onClick={formHeading}
          type="button"
          className="hover:cursor-pointer bg-[#057aff] text-sm text-white p-2 rounded-md"
        >
          Contact Us
        </button>
      </div>
    </div>
  );
}

export default EmailPageCardComponent;
