import "./ModalForm.css";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Button } from "reactstrap";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

function ModalForm({ submit }) {
  const [fbLeads, setFbLeads] = useState("");
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    reset,
  } = useForm();

  useEffect(() => {
    // Get the full URL
    const fullUrl = window.location.href;

    // Parse the URL and query parameters
    const url = new URL(fullUrl);
    const params = new URLSearchParams(url.search);

    // Get the 'utm_source' parameter
    const utmSource = params.get("utm_source");

    // Determine the value for fbLeads based on utmSource
    let leadsValue = "";
    switch (utmSource) {
      case "facebook_organic":
        leadsValue = "Fb Organic";
        break;
      case "Google_CPC":
        leadsValue = "Google CPC";
        break;
      case "instagram_organic":
        leadsValue = "Instagram Organic";
        break;
      case "linkedin_organic":
        leadsValue = "Linkedin Organic";
        break;
      case "PPL_DelhiNCR":
        leadsValue = "PPL Delhi NCR";
        break;
      case "Diwali_Offer":
        leadsValue = "Diwali Offer";
        break;
      case "INSTAGRAM_PAID":
        leadsValue = "INSTAGRAM PAID";
        break;
      case "Whatsapp_API":
        leadsValue = "Whatsapp API";
        break;
      case "Bangalore_data":
        leadsValue = "W Bangalore";
        break;
      case "Phamphlet":
        leadsValue = "Phamphlet";
        break;
      case "Deman_Gen":
        leadsValue = "Demand Gen";
        break;
      case "FACEBOOK":
        leadsValue = "Fb";
        break;
      case "EMAIL":
        leadsValue = "EMAIL";
        break;
      case "SMS":
        leadsValue = "SMS";
        break;
      case "Raw_Data":
        leadsValue = "Raw Data";
        break;
      case "Whatsapp_new":
        leadsValue = "Whatsapp";
        break;
      case "Offer":
        leadsValue = "Offer";
        break;
      case "Bings_Ads":
        leadsValue = "Bings Ads";
        break;
      case "Google_PPLJAN":
        leadsValue = "Google PPLJAN";
        break;
      case "Insta_Inpaid":
        leadsValue = "Insta IN";
        break;
      case "FB_Inpaid":
        leadsValue = "FB IN";
        break;
      case "Whatsapp_In":
        leadsValue = "Whatsapp IN";
        break;
      case "Email_In":
        leadsValue = "Email IN";
        break;
      case "SMS_In":
        leadsValue = "SMS IN";
        break;
      default:
        leadsValue = "nextbigbox.io";
    }
    // Set the fbLeads state
    setFbLeads(leadsValue);
  }, []);

  const showToast = (status) => {
    switch (status) {
      case "success":
        toast.success("Form Submitted Successfully.", {
          position: "top-right",
          autoClose: 1000, // Close the toast after 1 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        break;
      case "error":
        toast.error("Something Went Wrong.", {
          position: "top-right",
          autoClose: 1000, // Close the toast after 1 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        break;
      default:
    }
  };

  const onSubmit = async (data) => {
    console.log(data);
    let formData = new FormData();
    formData.append("uname", data.first_name);
    formData.append("company" ,data.company_name)
    formData.append("email", data.email);
    formData.append("mobile", Number(data.mobile));
    formData.append("utmSource", fbLeads);
    formData.append("servicesType", data.services);
    formData.append("remarks", data.message);
    formData.append("city", data.city);
    // https://nextbigbox.org/nbb.sale-crm/

    const res = await axios.post(
      "https://salescrm.nextbigbox.tech/api/addNewWebsiteLead",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (res) {
      showToast("success");
      reset();
    } else {
      showToast("error");
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-wrap items-start justify-center w-full h-full bg-gray-100 rounded-2xl p-5">
          <div className="w-full md:w-auto lg:w-auto flex flex-col justify-start items-start gap-2 m-2">
            <label htmlFor="first_name">Full Name</label>
            <input
              type="text"
              id="first_name"
              placeholder="Enter Your Name"
              {...register("first_name", { required: true })}
              className="h-[2rem] rounded-md p-2 w-full"
            />
            {errors.first_name?.type === "required" && (
              <p role="alert" className="form-error">
                Name is required
              </p>
            )}
          </div>

          <div className="w-full md:w-auto lg:w-auto flex flex-col justify-start items-start gap-2 m-2">
            <label htmlFor="company_name">Company Name</label>
            <input
              type="text"
              id="company_name"
              placeholder="Enter Your Company Name"
              {...register("company_name", { required: true })}
              className="w-full h-[2rem] rounded-md p-2"
            />
            {errors.company_name?.type === "required" && (
              <p role="alert" className="form-error">
                Company Name is required.
              </p>
            )}
          </div>

          <div className="w-full md:w-auto lg:w-auto flex flex-col justify-start items-start gap-2 m-2">
            <label htmlFor="mobile">Mobile</label>
            <input
              type="text"
              id="mobile"
              placeholder="Enter Your Mobile No"
              {...register("mobile", {
                required: "Mobile number is required",
                minLength: {
                  value: 10,
                  message: "Mobile number should be at least 10 digits",
                },
                maxLength: {
                  value: 10,
                  message: "Mobile number should be at most 10 digits",
                },
              })}
              aria-invalid={errors.mobile ? "true" : "false"}
              className="w-full h-[2rem] rounded-md p-2"
            />
            {errors.mobile && (
              <p role="alert" className="form-error">
                {errors.mobile.message}
              </p>
            )}
          </div>

          <div className="w-full md:w-auto lg:w-auto flex flex-col justify-start items-start gap-2 m-2">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              placeholder="Enter Your Email"
              {...register("email", { required: "Email is required" })}
              aria-invalid={errors.email ? "true" : "false"}
              className="w-full h-[2rem] rounded-md p-2"
            />
            {errors.email?.type === "required" && (
              <p role="alert" className="form-error">
                Email is required.
              </p>
            )}
          </div>

          <div className="w-full md:w-auto lg:w-auto flex flex-col justify-start items-start gap-2 m-2">
            <label htmlFor="services">Services</label>
            <select
              id="services"
              {...register("services", { required: true })}
              aria-invalid={errors.services ? "true" : "false"}
              className="w-full h-[2rem] rounded-md p-2"
            >
              <option value={"Choose an Option"} disabled={true}>
                Choose an Service
              </option>
              <option value={"API Integration"}>API Integration</option>
              <option value={"PPC Ads"}>PPC Ads</option>
              <option value={"SEO"}>SEO</option>
              <option value={"Web Development"}>Web Development</option>
              <option value={"CRM"}>CRM</option>
              <option value={"Social Media Marketing"}>
                Social Media Marketing
              </option>
            </select>
            {errors.services?.type === "required" && (
              <p role="alert" className="form-error">
                Service selection is required.
              </p>
            )}
          </div>

          <div className="w-full md:w-auto lg:w-auto flex flex-col justify-start items-start gap-2 m-2">
            <label htmlFor="city">City</label>
            <input
              type="text"
              id="city"
              placeholder="Enter Your City"
              {...register("city", { required: true })}
              aria-invalid={errors.city ? "true" : "false"}
              className="w-full h-[2rem] rounded-md p-2"
            />
            {errors.city?.type === "required" && (
              <p role="alert" className="form-error">
                City is required.
              </p>
            )}
          </div>

          <div className="w-full md:w-auto lg:w-auto flex flex-col justify-center items-start m-2">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              rows="4"
              cols="50"
              placeholder="Write your message here..."
              {...register("message", { required: true })}
              aria-invalid={errors.message ? "true" : "false"}
              className="w-full rounded-md p-2 mt-2"
            />
            {errors.message?.type === "required" && (
              <p role="alert" className="form-error">
                Message is required.
              </p>
            )}
          </div>

          <div className="w-full flex flex-col justify-center items-center m-2">
            {/* <input
              type="submit"
              disabled={isSubmitting}
              className="m-4 rounded-lg bg-blue-600 text-white px-16 py-2 hover:cursor-pointer"
            /> */}

            <Button
              type="submit"
              disabled={isSubmitting}
              
              className="bg-[#057aff] py-2 px-14 rounded-md text-white hover:cursor-pointer"
            >
              {isSubmitting ? (
                <Spin
                  indicator={
                    <LoadingOutlined spin style={{ color: "white" }} />
                  }
                />
              ) : (
                "SUBMIT"
              )}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}

export default ModalForm;
