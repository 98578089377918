import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../Components/CommonComponents/Navbar";
import NewFooterComponent from "../Components/CommonComponents/NewFooterComponent/NewFooterComponent";
import SectionCard from "../Components/CommonComponents/SectionCard";
import HrmsPageContent from "../Components/HrmsPageContent/HrmsPageContent";
import Seo from "../Components/CommonComponents/Seo";

function Hrms() {
  const [test, setTest] = useState(false);
  const isNavbarOpened = () => {
    setTest((prev) => !test);
  };
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <div className="">
      <Seo
        name={"Nextbigbox"}
        type={"Article"}
        title={"HRMS CRM | Human Resource Management System by NextBigBox"}
        description={
          "NextBigBox create a HRMS aap that manage all your Hr task and employee work report, attandance and tracking the employee work and Solutions your specific business needs."
        }
        canonical_url="https://www.nextbigbox.io/hrms"
        metaKeywords={[
          "Best HRMS software",
          "HR management software",
          "Best HRMS system",
          "Best HRMS software",
          "HRMS features",
          "HRMS benefits",
          "HRMS for small businesses",
        ]}
      />
      {/* Contatc Page Started  */}
      {/* Navbar Started  */}
      <Navbar isNavbarOpened={isNavbarOpened} isHomePage={false} />
      {/* Navbar End  */}
      {/* <SalesCrmPageContent /> */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-auto"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <HrmsPageContent />
      </SectionCard>

      {/* Crm content Started */}
      {/* <CrmPageContent /> */}
      {/* Crm content End */}

      {/* Footer Started  */}
      <SectionCard
        position={"relative"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800"}
        visible={test ? "hidden" : "show"}
      >
        <NewFooterComponent />
      </SectionCard>
      {/* Footer End  */}
      {/* Contact Page End  */}
    </div>
  );
}

export default Hrms;
