import React from "react";
import styled, { keyframes } from "styled-components";


import p1 from "../../media/p1.svg"
import p2 from "../../media/p2.svg"
import p3 from "../../media/p3.svg"
import p4 from "../../media/p4.svg"
import p5 from "../../media/p5.svg"
import p6 from "../../media/p6.svg"
import p7 from "../../media/p7.svg"
import p8 from "../../media/p8.svg"
// import p9 from "../../media/p9.svg"
// import p10 from "../../media/p10.svg"

const recognition = [
  p1,
  p2,
  p3,
  p4,
  p5,
  p6,
  p7,
  p8,
  // p9,
  // p10
];

// Keyframes for the animation
const scroll = keyframes`
    0% { transform: translateX(0); }
    50% { transform: translateX(-262px); }
    100% { transform: translateX(-525px); }
`;

// Styled components
const SliderContainer = styled.div`
  align-items: center;
  display: flex;
  height: 200px;
  max-width: 90%;
  margin: auto;
  overflow-x: hidden;
`;

const Slider = styled.div`
  display: flex;
  animation: ${scroll} 10s linear infinite;
  width: calc(1800px);
  gap: 2rem;
`;

const Slide = styled.div`
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const OurPartnerComponent = () => {
  // Double the images to ensure seamless looping
  const images = [...recognition];

  return (
    <div className="mt-0 mb-4 lg:mt-10">
      <SliderContainer>
        <Slider>
          {images.map((item, index) => (
            <Slide key={index}>
              <Image src={item} alt="Recognition" />
            </Slide>
          ))}
        </Slider>
      </SliderContainer>
    </div>
  );
};

export default OurPartnerComponent;
