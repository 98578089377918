import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ContactComponent from "../ContactComponent/ContactComponent";
import blogHeroImage from "../../../media/blogHeroImage.png";
import doubleArrow from "../../../media/angle-double-small-right.png";
import { Link } from "react-router-dom";
gsap.registerPlugin(ScrollTrigger);

function BlogsFullPageContent({ test }) {
  

  return (
    <div className="about_page_content lg:px-8">
      <div className="lg:pt-10 lg:mt-9 py-[4rem] lg:py-[6rem]">
        <h1 className="text-3xl text-center">
          How to digital marketing grow your business?
        </h1>
        <p className="text-center px-8 lg:px-0 mt-1 font-medium">
          All you need to know Digital Marketing.
        </p>
      </div>
      <div>

      </div>

    </div>
  );
}

export default BlogsFullPageContent;
