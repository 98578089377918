import React from "react";
import "./SmoContent.css";
import Smo from "../../media/smo_hero_banner.webp";
import CtaComponent from "../CommonComponents/CtaComponent/CtaComponent";
import whyChooseOurLs from "../../media/smo_services.webp";
import SectionCard from "../CommonComponents/SectionCard";
import ContactComponent from "../CommonComponents/ContactComponent/ContactComponent";

function SmoContent() {

  const DemoCtaText = "Ready for a Hassle-free SMO?";

  return (
    <>
      <div className="smo_main">
        <section className="fintech_support smo_page">
          <div className="left mt-24" data-aos="zoom-out-right">
            <img src={Smo} alt="img" />
          </div>
          <div className="right" data-aos="zoom-out-left">
            <h2>Social Media Optimization</h2>
            <p>
              It revolves around developing an online space where individuals
              converge, talk, engage, change statistics, and establish
              connections.
            </p>
            <ul>
              <li>
                <b>Profile Optimization: </b> Complete profile, explicit
                visualization, branding for the higher visibility.
              </li>
              <li>
                <b>Content Strategy: </b> As per the brand and business creating
                captivating engaging content.
              </li>
              <li>
                <b>Keywords & Hashtags: </b> Insertion of trending and
                functioning keywords and hashtags selection.
              </li>
              <li>
                <b>Engagement:</b> Creating a robust chain for the engagement of
                customers.
              </li>
              <li>
                <b>Posting Schedule: </b> Posting with a perfect schedule and
                calendars.
              </li>
              <li>
                <b>Analytics and Optimization:</b> A brief analysis of the
                content and their engagement.
              </li>
            </ul>
          </div>
        </section>
        <section className="sp9s3">
          <h1>Services offered by Nextbigbox for Social Media Optimization</h1>
          <ul>
            <li>
              <h2>Creatives and Management</h2>
              <p>
                Our team especially focus on the creativity for the ad campaign
                and management for better performance and enhancement of the
                visibility of the brand which is the basic task of our social
                media optimization services.
              </p>
            </li>
            <li>
              <h2>Strategic tweeting </h2>
              <p>
                With the keen and robust strategy, our social media team
                implement the tweeting and posting any creatives on social media
                and it helps in enhancing the reach and branding at wider
                platforms.
              </p>
            </li>
            <li>
              <h2>Active engagement in forums </h2>
              <p>
                With active engagement in different business forums and
                communities, we ensure a higher reach of customers for better
                engagement which is the best and most effective strategy.
              </p>
            </li>
            <li>
              <h2>RRS Feed Sharing</h2>
              <p>
                Ditch the generic tweets and amplify your brand's unique voice
                with our Twitter expertise. We craft impactful messages that
                resonate with your audience, sparking conversations and
                rocketing your reach. No need to shout into the void – our
                strategic tweeting services transform your brand into a Twitter
                rockstar, driving engagement and awareness that gets you
                noticed.
              </p>
            </li>
            <li>
              <h2>
                Integration of creative and video in the popular online
                community
              </h2>
              <p>
                With the integration of videos in online communities we try to
                enhance the reach of customers via perfect branding and
                visibility enhancements.
              </p>
            </li>
            <li>
              <h2>Analytics and Reporting:</h2>
              <p>
                We always keep an eye on the reporting and the analytics for
                better service delivery so that our clients can have the desired
                results and higher revenue.
              </p>
            </li>
          </ul>
        </section>
      </div>

      <CtaComponent
        description={DemoCtaText}
        scrollVal={2000}
        scrollDuration={100}
      />
      <div className="smo_main smo_main_middle">
        <section className="sp6s3">
          <div className="left">
            <img className="p-6" src={whyChooseOurLs} alt="img" />
          </div>
          <div className="right">
            <h1>
              How Our SMO Services Can Help to be available for the Next
              Campaign.
            </h1>
            <ul>
              <li>
                <b>Audience Insights:</b> With the brief analysis research of
                the audience insight we can target the audience and then set the
                campaign.
              </li>
              <li>
                <b>Enhanced Visibility:</b>It’s essential to enhance the
                visibility of the products and brands so that we can ensure the
                number of customers and generate higher marketing values.
              </li>
              <li>
                <b>Community Building:</b> With the robust connection and
                community building, we always try to expand the area and density
                of customers so that the transformation rate can be higher.
              </li>
              <li>
                <b>Content Testing:</b> We always monitor and check the content
                so that it can be easy to create a better connectivity
                engagement ratio for the customer and clients.
              </li>
              <li>
                <b>Effective Targeting:</b> Targeting efficient customers so
                that the transformation ratio and lead conversion can be easy
                with social media optimization.
              </li>
              <li>
                <b>Algorithm Adaptation:</b> We always implement the algorithm
                adoption so that the upcoming algorithm can’t affect the
                campaign.
              </li>
            </ul>
          </div>
        </section>
      </div>
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
      >
        <ContactComponent />
      </SectionCard>
    </>
  );
}

export default SmoContent;
