import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../Components/CommonComponents/Navbar";
import SectionCard from "../Components/CommonComponents/SectionCard";
import FintechServicesContent from "../Components/FintechServicesContent/FintechServicesContent";
import NewFooterComponent from "../Components/CommonComponents/NewFooterComponent/NewFooterComponent";
import Seo from "../Components/CommonComponents/Seo";

function FintechServices() {
  const [test, setTest] = useState(false);
  const isNavbarOpened = () => {
    setTest((prev) => !test);
  };
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <div className="">
      <Seo
        name={"Nextbigbox"}
        type={"Article"}
        title={
          "Fintech Solution services for your Startup company | NextBigBox"
        }
        description={
          "Our Best Fintech Solution services to help your Startup Business. our expert Tech Support, Marketing Support and Operational Support to growth your Fintech Business."
        }
        canonical_url="https://www.nextbigbox.io/fintech-solution"
        metaKeywords={[
          "fintech solution",
          "fintech services",
          "innovative fintech",
          "business finance solutions",
          "fintech for businesses",
        ]}
      />
      {/* Contact Page Started  */}
      {/* Navbar Started  */}
      <Navbar isNavbarOpened={isNavbarOpened} isHomePage={false} />
      {/* Navbar End  */}
      {/* <SalesCrmPageContent /> */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-auto"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <FintechServicesContent />
      </SectionCard>

      {/* Crm content Started */}
      {/* <CrmPageContent /> */}
      {/* Crm content End */}

      {/* Footer Started  */}
      <SectionCard
        position={"relative"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800"}
        visible={test ? "hidden" : "show"}
      >
        <NewFooterComponent />
      </SectionCard>
      {/* Footer End  */}
      {/* Contact Page End  */}
    </div>
  );
}

export default FintechServices;
