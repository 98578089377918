import { React, useState, useEffect } from "react";
import Navbar from "../Components/CommonComponents/Navbar";
import NewFooterComponent from "../Components/CommonComponents/NewFooterComponent/NewFooterComponent";
import SMM from "../media/SMM_Logo.png";
import fintechImage from "../media/fintechImage.svg";
import ApiIntegrationImage from "../media/API.jpg";
import crmImage from "../media/CRM.jpg";
import PpcAds from "../media/PPC-Ad.jpg";
import CtaSection from "../Components/CommonComponents/CtaSection/CtaSection";
import emailMarketingImage from "../media/emailMarketing.svg";
import WebDevelopmentImage from "../media/WEB (1).jpg";
import Banner from "../media/io_banner.jpg";
import ClientComponent from "../Components/CommonComponents/ClientComponent/ClientComponent";
import SectionCard from "../Components/CommonComponents/SectionCard";
import SlickCarousel from "../Components/SlickCarousal/SlickCarousel";
import FaqSectionComponent from "../Components/CommonComponents/FaqSectionComponent/FaqSectionComponent";
import WebSeoHero from "../media/SEO.jpg";
import ModalForm from "../Components/CommonComponents/ModalForm/ModalForm";
import { Modal } from "antd";
import ContactComponent from "../Components/CommonComponents/ContactComponent/ContactComponent";
import BottomContactForm from "../Components/CommonComponents/BottomContactForm/BottomContactForm";
import { FormGroup, Label } from "reactstrap";
import EmailPageCardComponent from "../Components/CommonComponents/EmailPageCardComponent/EmailPageCardComponent";
import SliderComponent from "../Components/CommonComponents/SliderComponent";
import OurPartnerComponent from "../Components/CommonComponents/OurPartnerComponent";

const EmailMarketingLandingPage = () => {
  const [test, setTest] = useState(false);
  const [heading, setHeading] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const formHeadingSetter = (index) => {
    setHeading(allServices[index].title);
    showModal();
  };
  const showModal = () => {
    setIsModalOpen((prev) => !prev);
  };
  const handleCancel = () => {
    setIsModalOpen((prev) => !prev);
  };
  const isNavbarOpened = () => {
    setTest((prev) => !test);
  };

  const allServices = [
    {
      title: "API Integration",
      description:
        "Our strength lies on both front-end and back-end development, where our experts' innovative ideas help you establish your online business and build a brand.",
      image: ApiIntegrationImage,
    },
    {
      title: "PPC Ads",
      description:
        "Boost your business growth with Pay-Per-Click (PPC) services. PPC effectively targets audiences, enhances brand recognition, drives website traffic, and increases sales and leads, ensuring swift and sustainable business growth.",
      image: PpcAds,
    },
    {
      title: "SEO",
      description:
        "In today's digital age, about 50% of website traffic comes from organic searches, making SEO essential for businesses aiming to boost online presence and revenue.",
      image: WebSeoHero,
    },
    {
      title: "Web Development",
      description:
        "Our strength lies in both front-end and back-end development, where our expert's innovative ideas help you quickly establish your online business and build a brand.",
      image: WebDevelopmentImage,
    },
    {
      title: "Customized CRM Software",
      description:
        "For the enhancement of your sales through the CRM we provide customized CRM's requirement that can accumulate all the data including leads, current status and all.",
      image: crmImage,
    },
    {
      title: "Social Media Marketing",
      description:
        "Our marketing strategies are the best component to enhance your brand visibilites in a wider area of customer so that in a limited period, your product can purse the journey of business to brand.",
      image: SMM,
    },
  ];
  const faqData = [
    {
      q: "What is Email Marketing?",
      a: "Email Marketing is one of the best components of digital marketing where you can find enormous clients and potential customers, especially for your business.",
    },
    {
      q: "How is it different from other digital marketing services?",
      a: "It is a lead generation campaign which is accomplished through email where all the tricks like creative design, content, subject lines are used to engage and attract clients or customers.",
    },
    {
      q: " What are Web SEO Services?",
      a: "It’s an essential part of digital marketing which is used to enhance the ranking of websites for the success of business with the customers reach.",
    },
    {
      q: "What are Pay-per-click services?",
      a: "It’s the most efficient digital marketing service that helps you grow your business with extended customer reach, increased website traffic and many more things.",
    },
    {
      q: "How much will it cost for effective and transformative services?",
      a: "As per your package, data, numbers of emails, and everything, it will vary. However, we always provide our services at an affordable price.",
    },
  ];
  const faqHeading =
    "Indeed, you might have lots of questions about the loan management system that how it could cultivate your business. Therefore, some essential questions are there which are frequently asked by our esteemed customers and clients.";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar isNavbarOpened={isNavbarOpened} isHomePage={false} />
      <div className={`${test ? "hidden" : "visible"} pt-[3.5rem]`}>
        {/* Modal Form Start Here */}
        <Modal
          title={
            <h2 className="text-center text-xl font-montserrat font-bold">
              {heading}
            </h2>
          }
          open={isModalOpen}
          centered
          onCancel={handleCancel}
          footer={null}
        >
          <ModalForm />
        </Modal>
        {/* Modal Form Ends Here */}

          <section>
            <div
              className="flex flex-wrap justify-between px-[2rem] lg:px-[5.3rem] py-20"
              style={{
                backgroundImage: `url(${Banner})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="flex flex-col w-full lg:w-2/5 justify-center items-center ">
                <h1 className="font-semibold font-oswald text-[2rem] lg:text-[3rem] text-start leading-[3rem] text-white">
                  Fueling growth with smart strategies and digital mastery
                </h1>
                <h3 className=" mt-4 font-montserrat text-xl lg:text-xl lg:mt-8 mb-16 text-white">
                  Let us improve your online presence and get you more genuine
                  leads
                </h3>
              </div>
              <div className=" flex flex-col justify-center items-center w-full lg:w-2/5 md:1/3">
                <ModalForm />
              </div>
            </div>
          </section>

          <SectionCard
            position={"relative"}
            height={"h-auto"}
            width={"w-auto"}
            backgroundColor={"bg-red"}
            color={"text-yellow"}
            className={"text-slate-800 mt-20"}
            visible={test ? "hidden" : "show"}
          >
            <OurPartnerComponent Heading={"Our Partners"}/>
          </SectionCard>

          <section>
            <h2 className="mt-0 mb-4 lg:mt-8 font-bold text-[1.8rem] text-center ">
              Our Digital Solutions
            </h2>
            <p className="text-center px-4">
              Comprehensive Digital Solutions: Your all-in-one partner for
              seamless digital growth and success.
            </p>
            <div className="flex flex-wrap mt-20 mb-20 gap-4 justify-center">
              {allServices.map((data, index) => (
                <EmailPageCardComponent
                  key={index} // Use a unique id from data or index as fallback
                  data={data}
                  altText ={data.title}
                  formHeading={() => formHeadingSetter(index)}
                />
              ))}
            </div>
          </section>
          {/* Our Clients Start */}
          <SectionCard
            position={"relative"}
            height={"h-auto"}
            width={"w-auto"}
            backgroundColor={"bg-red"}
            color={"text-yellow"}
            className={"text-slate-800 mt-20"}
            visible={test ? "hidden" : "show"}
          >
            <ClientComponent Heading="We're Trusted by Leading Brands" />
          </SectionCard>
          {/* Our Clients End */}
          {/* Book Your Consultation Today - Start here */}
          <SectionCard
            position={"relative"}
            height={"h-auto"}
            width={"w-auto"}
            backgroundColor={"bg-red"}
            color={"text-yellow"}
            className={"text-slate-800 mt-20"}
            visible={test ? "hidden" : "show"}
          >
            <CtaSection />
          </SectionCard>
          {/* Book Your Consultation Today - Ends here */}
          <FaqSectionComponent faqData={faqData} faqHeading={faqHeading} />
          <BottomContactForm padding={true} />
      

        <NewFooterComponent />
      </div>
    </div>
  );
};

export default EmailMarketingLandingPage;
