import React, { useState, useEffect } from "react";
import "./BottomContactForm.css";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import ContactUs from "../../../media/CrmContactImage.jpg";

import { FormGroup, Label } from "reactstrap";
import { width } from "@fortawesome/free-solid-svg-icons/fa0";

function BottomContactForm({ padding }) {
  const [fbLeads, setFbLeads] = useState("");

  useEffect(() => {
    // Get the full URL
    const fullUrl = window.location.href;

    // Parse the URL and query parameters
    const url = new URL(fullUrl);
    const params = new URLSearchParams(url.search);

    // Get the 'utm_source' parameter
    const utmSource = params.get("utm_source");

    // Determine the value for fbLeads based on utmSource
    let leadsValue = "";
    switch (utmSource) {
      case "facebook_organic":
        leadsValue = "Fb Organic";
        break;
      case "Google_CPC":
        leadsValue = "Google CPC";
        break;
      case "instagram_organic":
        leadsValue = "Instagram Organic";
        break;
      case "linkedin_organic":
        leadsValue = "Linkedin Organic";
        break;
      case "PPL_DelhiNCR":
        leadsValue = "PPL Delhi NCR";
        break;
      case "Diwali_Offer":
        leadsValue = "Diwali Offer";
        break;
      case "INSTAGRAM_PAID":
        leadsValue = "INSTAGRAM PAID";
        break;
      case "Whatsapp_API":
        leadsValue = "Whatsapp API";
        break;
      case "Bangalore_data":
        leadsValue = "W Bangalore";
        break;
      case "Phamphlet":
        leadsValue = "Phamphlet";
        break;
      case "Deman_Gen":
        leadsValue = "Demand Gen";
        break;
      case "FACEBOOK":
        leadsValue = "Fb";
        break;
      case "EMAIL":
        leadsValue = "EMAIL";
        break;
      case "SMS":
        leadsValue = "SMS";
        break;
      case "Raw_Data":
        leadsValue = "Raw Data";
        break;
      case "Whatsapp_new":
        leadsValue = "Whatsapp";
        break;
      case "Offer":
        leadsValue = "Offer";
        break;
      case "Bings_Ads":
        leadsValue = "Bings Ads";
        break;
      case "Google_PPLJAN":
        leadsValue = "Google PPLJAN";
        break;
      case "Insta_Inpaid":
        leadsValue = "Insta IN";
        break;
      case "FB_Inpaid":
        leadsValue = "FB IN";
        break;
      case "Whatsapp_In":
        leadsValue = "Whatsapp IN";
        break;
      case "Email_In":
        leadsValue = "Email IN";
        break;
      case "SMS_In":
        leadsValue = "SMS IN";
        break;
      default:
        leadsValue = "nextbigbox.io";
    }
    // Set the fbLeads state
    setFbLeads(leadsValue);
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const onSubmit = async (data) => {
    console.log(data);
    let formData = new FormData();
    formData.append("uname", data.fullName);
    formData.append("company", data.CompanyName);
    formData.append("email", data.email);
    formData.append("mobile", Number(data.mobile));
    formData.append("utmSource", fbLeads);
    formData.append("servicesType", data.services);
    formData.append("remarks", data.message);
    formData.append("city", data.city);
    // https://nextbigbox.org/nbb.sale-crm/

    const res = await axios.post(
      "https://salescrm.nextbigbox.tech/api/addNewWebsiteLead",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (res) {
      showToast("success");
      // reset();
    } else {
      showToast("error");
    }
  };
  // toast message
  const showToast = (status) => {
    switch (status) {
      case "success":
        toast.success("Form Submitted Successfully.", {
          position: "top-right",
          autoClose: 1000, // Close the toast after 1 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        break;
      case "error":
        toast.error("Something Went Wrong.", {
          position: "top-right",
          autoClose: 1000, // Close the toast after 1 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        break;
      default:
    }
  };

  return (
    <div
      id="contact"
      className="main_contact_component md:px-[5.3rem] md:py-[4rem]"
    >
      <div className="main_contact_component_left">
        <h4 className="font-oswald mt-12">Let's Contact Us!</h4>
        {/* <div className="ml-2 mt-1">
          <span>Email us at : </span> <a href="mailto:reachus@nextbigbox.io">reachus@nextbigbox.io</a>
        </div> */}
        <img src={ContactUs} alt="Contact us" style={{ width: "550px" }} />
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="main_contact_component_right"
        noValidate
      >
        <FormGroup className="half">
          <Label htmlFor="full_name">Full Name</Label>
          <input
            type="text"
            id="full_name"
            placeholder="Enter Your Full Name"
            {...register("fullName", { required: true })}
            aria-invalid={errors.fullName ? "true" : "false"}
          />
          {errors.fullName?.type === "required" && (
            <p role="alert" className="form-error">
              Full Name is required
            </p>
          )}
        </FormGroup>{" "}
        <FormGroup className="half">
          <Label htmlFor="company_name">Company Name</Label>
          <input
            type="text"
            id="company_name"
            placeholder="Enter Your Company Name"
            {...register("CompanyName", { required: true })}
            aria-invalid={errors.CompanyName ? "true" : "false"}
          />
          {errors.CompanyName?.type === "required" && (
            <p role="alert" className="form-error">
              Company Name is required.
            </p>
          )}
        </FormGroup>
        <FormGroup className="half">
          <Label htmlFor="mobile_number">Mobile</Label>
          <input
            type="text"
            id="mobile_number"
            placeholder="Enter Your Mobile No"
            {...register("mobile", {
              required: "Mobile number is required",
              minLength: {
                value: 10,
                message: "Mobile number should be at least 10 digits",
              },
              maxLength: {
                value: 10,
                message: "Mobile number should be at most 10 digits",
              },
            })}
            aria-invalid={errors.mobile ? "true" : "false"}
          />
          {errors.mobile && (
            <p role="alert" className="form-error">
              {errors.mobile.message}
            </p>
          )}
        </FormGroup>
        <FormGroup className="half">
          <Label htmlFor="email_id">Email</Label>
          <input
            type="email"
            id="email_id"
            placeholder="Enter Your Email"
            {...register("email", { required: true })}
            aria-invalid={errors.email ? "true" : "false"}
          />
          {errors.email?.type === "required" && (
            <p role="alert" className="form-error">
              Email is required.
            </p>
          )}
        </FormGroup>
        <FormGroup className="half">
          <Label htmlFor="project_type">Services</Label>
          <select
            id="project_type"
            {...register("services", { required: true })}
            aria-invalid={errors.services ? "true" : "false"}
          >
            <option  disabled ={true} value={"Choose an Option"}>
              Choose Service Type
            </option>
            <option value={"API Integration"}>API Integration</option>
            <option value={"PPC Ads"}>PPC Ads</option>
            <option value={"SEO"}>SEO</option>
            <option value={"Web Development"}>Web Development</option>
            <option value={"CRM"}>CRM</option>
            <option value={"Social Media Marketing"}>
              Social Media Marketing
            </option>
          </select>
          {errors.services?.type === "required" && (
            <p role="alert" className="form-error">
              Services is required.
            </p>
          )}
        </FormGroup>
        <FormGroup className="half">
          <Label>City</Label>
          <input
            type="text"
            placeholder="Enter your city name"
            {...register("city", { required: true })}
            aria-invalid={errors.city ? "true" : "false"}
          />
          {errors.city?.type === "required" && (
            <p role="alert" className="form-error">
              City is required.
            </p>
          )}
        </FormGroup>
        <FormGroup className="full">
          <Label>Message</Label>
          <textarea
            rows="2"
            cols="5"
            placeholder="Type Your Message..."
            style={{ width: "98%" }}
            {...register("message", { required: true })}
            aria-invalid={errors.message ? "true" : "false"}
          />
          {errors.message?.type === "required" && (
            <p role="alert" className="form-error">
              Message is required.
            </p>
          )}
        </FormGroup>
        <FormGroup className="full submit mt-6">
          <input
            type="submit"
            placeholder="SUMBIT"
            className="hover:cursor-pointer"
          />  
        </FormGroup>
      </form>
      {/* <ToastContainer
        position="top-right" // Set the position of the notifications
        autoClose={3000} // Set the auto-close duration (3 seconds)
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        draggable
        progressClassName="toast-progress"
        zIndex={1000} // Set the desired z-index value
      /> */}
    </div>
  );
}

export default BottomContactForm;
