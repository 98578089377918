import React, { useEffect, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ContactComponent from "../ContactComponent/ContactComponent";
import blogHeroImage from '../../../media/blogHeroImage.png'
import doubleArrow from '../../../media/angle-double-small-right.png'
import { Link } from "react-router-dom";
import axios from "axios";
import { Truck } from "react-bootstrap-icons";
gsap.registerPlugin(ScrollTrigger);

function BlogsPageContent({ test }) {

  const [blogData, setBlogData] = useState();
  // Demo Cta Test
  // scroll animation
 useEffect(() => {
  fetchBlogs()
 }, [])
  

  // const blogData = [
  //   {
  //     id: '1',
  //     img: blogHeroImage,
  //     heading: 'How to digital marketing grow your business?',
  //     date: '12-01-2023',
  //     time: '4 Min',
  //     link: "https://www.example.com",
  //   },
  //   {
  //     id: '1',
  //     img: blogHeroImage,
  //     heading: 'How to digital marketing grow your business?',
  //     date: '12-01-2023',
  //     time: '4 Min',
  //     link: "https://www.example.com",
  //   },
  //   {
  //     id: '1',
  //     img: blogHeroImage,
  //     heading: 'How to digital marketing grow your business?',
  //     date: '12-01-2023',
  //     time: '4 Min',
  //     link: "https://www.example.com",
  //   },
  //   {
  //     id: '1',
  //     img: blogHeroImage,
  //     heading: 'How to digital marketing grow your business?',
  //     date: '12-01-2023',
  //     time: '4 Min',
  //     link: "https://www.example.com",
  //   },
  //   {
  //     id: '1',
  //     img: blogHeroImage,
  //     heading: 'How to digital marketing grow your business?',
  //     date: '12-01-2023',
  //     time: '4 Min',
  //     link: "https://www.example.com",
  //   },
  //   {
  //     id: '1',
  //     img: blogHeroImage,
  //     heading: 'How to digital marketing grow your business?',
  //     date: '12-01-2023',
  //     time: '4 Min',
  //     link: "https://www.example.com",
  //   },
  //   {
  //     id: '1',
  //     img: blogHeroImage,
  //     heading: 'How to digital marketing grow your business?',
  //     date: '12-01-2023',
  //     time: '4 Min',
  //     link: "https://www.example.com",
  //   },
  //   {
  //     id: '1',
  //     img: blogHeroImage,
  //     heading: 'How to digital marketing grow your business?',
  //     date: '12-01-2023',
  //     time: '4 Min',
  //     link: "https://www.example.com",
  //   },
  // ]

  useEffect(() => {
    fetchBlogs();
  }, [])

  const fetchBlogs = async () => {
    try {
      const data = await axios.get(
        "https://www.nextbigbox.io/wp-json/wp/v2/posts/"
      );
      setBlogData(data.data);
      
    } catch (error) {
      console.log(error)
    }
  }

  // truncate string
  function truncateString(str, length, continuous) {
    if (str.length > length) {
      if(!continuous) {
        return str.substring(0, length);
      } else {
        return str.substring(0, length) + '...';
      }
    }
    return str;
  }
  
  return (
    <div className="about_page_content lg:px-8">
      <div className="lg:pt-14 lg:mt-9 py-[4rem] lg:py-[6rem]">
        <h1 className="text-3xl text-center">Our Latest Blogs</h1>
        <p className="text-center px-8 lg:px-0 mt-1 font-medium">
          All you need to know software development and Digital Marketing.
        </p>
      </div>
      <div className=" flex flex-col lg:flex-row px-8 lg:px-0 justify-center gap-8 m-auto">
        <img
          src={
            blogData ? blogData[9]?.jetpack_featured_media_url : blogHeroImage
          }
          alt="blog"
          className="w-100 lg:w-[25rem] border p-2 rounded-lg"
        />
        <div className="w-100 lg:w-[30rem]">
          <div className="flex flex-row gap-2">
            <p className="text-[.8rem] font-semibold px-[8px] py-[3px] rounded-xl bg-gray-200">
              {blogData ? truncateString(blogData[9]?.date, 10, 0) : ""}
            </p>{" "}
            <p className="text-[.8rem] font-semibold px-[8px] py-[3px] rounded-xl bg-gray-200">
              {" "}
              6 Min Read
            </p>
          </div>
          <h2 className="mt-4 text-[1.1rem] font-bold ">
            {blogData ? blogData[9]?.title.rendered : ""}
          </h2>
          <p className="mt-2">
            Digital marketing can significantly boost your business by elevating
            its online presence and engagement. Through strategies like search
            engine optimization (SEO) and targeted advertising on platforms such
            as Google Ads and social media, you can effectively reach your
            desired audience. By optimizing your website's visibility in search
            engine results and precisely targeting your ads, you can attract
            more visitors, enhance brand recognition, and ultimately drive sales
            and revenue growth.
          </p>
        </div>
      </div>
      <div className="mt-14">
        <h2 className=" pl-12 font-semibold text-[3rem]">All Blogs</h2>
        <div className="main mt-4 p-1 px-[2rem] lg:px-[4rem] flex flex-row justify-center gap-6 flex-wrap">
          {blogData?.map((item, index) => {
            return (
              <div className="w-100 lg:w-[15rem] mb-4 item border rounded-xl border-gray-300 p-2 " key={index}>
                <img
                  className="w-100 lg:w-[15rem] rounded-xl max-h-[8rem] min-w-[100%]"
                  // src={blogHeroImage}
                  src={item.jetpack_featured_media_url}
                  alt="img"
                />
                <h3 className="mt-1 font-normal">
                  {truncateString(item.title.rendered, 40, 1)}
                </h3>
                <div className="my-1 flex flex-row gap-2 justify-between border-b-2 pb-2 border-gray-400">
                  <p className="text-[.8rem] font-semibold px-[8px] py-[3px] rounded-xl bg-gray-200">
                    {truncateString(item.date, 10, 0)}
                  </p>{" "}
                  <p className="text-[.8rem] font-semibold px-[8px] py-[3px] rounded-xl ">
                    {" "}
                    {/* {item.time || "4 Min Read"} */}
                  </p>
                </div>
                <Link
                  to={item.link}
                  target="blank"
                  className="border rounded-xl mt-2 flex flex-row justify-between gap-1 py-2 px-4"
                >
                  <button className="text-[1.1rem] font-semibold">
                    Read This Blog
                  </button>
                  <img className="w-6" src={doubleArrow} alt="arrow" />
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default BlogsPageContent;
