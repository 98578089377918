import React from "react";
import "./FintechServicesContent.css";
import fintech_solution_banner from "../../media/fintech_solution_banner.webp";
import FaqSectionComponent from "../CommonComponents/FaqSectionComponent/FaqSectionComponent";
import CtaComponent from "../CommonComponents/CtaComponent/CtaComponent";
import fintech_support from "../../media/fintech_support.webp";
import fintech_nbfc from "../../media/fintech_nbfc.webp";
import ContactComponent from "../CommonComponents/ContactComponent/ContactComponent";

function FintechServicesContent() {
  const DemoCtaText = "Wants to Setup fintech Business? Let's Connect With Us.";
  const faqData = [
    {
      q: "We can set up the company for you. ",
      a: "If you already have a company it’s great. If you have plans to set up a new company we have an expert team to help in that.",
    },
    {
      q: "We can help hire your resources to grow your business.",
      a: "We can help you hire the right resources who are experienced in handling similar businesses. In this business, as in all others, team experienced in similar business can give you the required headstart over others.",
    },
    {
      q: "We can give you the required marketing support.",
      a: "We have a large team of marketing professionals who will carry out your marketing. Marketing is the essence of this business as all customers are sourced from digital marketing.",
    },
    {
      q: "We can give you all the tech support.",
      a: "We provide CRM (Client Relationship Management) and tech support to ensure that your customer inquiry is safe. Each request and question are logged into a central system that can access online. This system is quite helpful for you to automate the entire loan lifecycle and rely on requirements. This software can help you get your customer info, make new loan demands, provide accurate reports, understand their specific situations, keep in touch with them, listen to their needs, and provide quick and attentive support.",
    },
    {
      q: "We can give you entire operational support.",
      a: "Our operations expert team will provide you with all the necessary support. Operations management involves planning, organizing, supervising processes, and making all the significant points to improve for higher profitability for your fintech company. Also, our operations experts can provide you with full support for the company’s strategic goals and analysis of the current processes. It is pretty significant to select the perfect key performance symbol or KPI (Key Performance Indicator).",
    },
  ];
  const faqHeading =
    "You might have lots of questions for the CRM and fintech services and we are there to resolve all your queries.";

  // window width
  let scrollValue = 0;
  const windowWidth = window.innerWidth;
  if (windowWidth > 500) {
    scrollValue = 2500;
  } else {
    scrollValue = 3500;
  }
  return (
    <div>
      <div className="main_fintech_page_content">
        <section className="fs_section_1">
          <div className="bottom" data-aos="zoom-in">
            <img
              src={fintech_solution_banner}
              className="fintech_solution_banner_img"
              alt="img"
            />
          </div>
          <div className="upper flex gap-6">
            <h1 data-aos="">
              Want to specialize in the Fintech business but don’t know how?
              We’ll help you!
            </h1>
            <p data-aos="">
              We have a team of experts which is profoundly skilled and have
              been working for multiple fintech companies. They better
              understand that how to associate your digital lending company with
              the registered NBFC and how to incept and run your business with
              all the required steps and techniques like hiring, training with
              technical skills, marketing, website building and their ranking as
              well. Overall, our team imparts precise and perfect solutions for
              your lending business.
            </p>
          </div>
        </section>
      </div>

      <CtaComponent
        description={DemoCtaText}
        btnText={"Request Demo"}
        scrollVal={scrollValue}
        scrollDuration={100}
      />
      <div className="main_api_page_content main_api_page_content_next">
        <section className="fintech_support">
          <div className="left" data-aos="zoom-out-right">
            <img src={fintech_support} alt="img" />
          </div>
          <div className="right" data-aos="zoom-out-left">
            <h2>We provide the following support for your company</h2>
            <p>
              You can easily incept your business and run it with our expertise
              in fintech support.
            </p>
            <ul>
              <li>
                <b>Company Setup : </b> We Can help you establish the Company
                For You.
              </li>
              <li>
                <b>Tech Support : </b>We are there for you for the tech support.
              </li>
              <li>
                <b>Training : </b>We prepare your employees with the requisite
                training.
              </li>
              <li>
                <b>Marketing Support : </b>We provide a precise Marketing
                Support
              </li>
              <li>
                <b>Operational Support : </b>During the Entire Operational
                Support we are there.
              </li>
              <li>
                <b>Hiring Your Resources : </b>We can help you hire perfect and
                suitable employees.
              </li>
            </ul>
          </div>
        </section>
        <section className="fintech_nbfc">
          <div className="left" data-aos="zoom-in-left">
            <h1>We Can Help You Getting Associated With NBFC.</h1>
            <p>
              With expertise in NBFC and digital lending services, we have been
              functioning with different fintech companies and have helped set
              up their fintech company with the registered NBFC. Considering all
              the guidelines of financial regulatory authorities and government
              IT rules, we help you get the CRM and Loan Management System for
              fair, hassle-free and transparent services.
            </p>
          </div>
          <div className="right" data-aos="zoom-in-right">
            <img src={fintech_nbfc} alt="img" />
          </div>
        </section>
        <section className="fs_faq_section">
          <FaqSectionComponent faqHeading={faqHeading} faqData={faqData} />
        </section>
      </div>
      <ContactComponent scrollVal={1000} scrollDuration={1000} />
    </div>
  );
}

export default FintechServicesContent;
